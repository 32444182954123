import React, { useEffect, useState, useCallback } from "react";
import "./Home.scss";
import { Grid, Container, Box, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  BsSearch,
  BsFillCloudHaze2Fill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { TbHeartRateMonitor } from "react-icons/tb";
import { TiWarning } from "react-icons/ti";
import Map from "../../Components/Map/MapSingle";
import axios from "axios";
import { dataAQMSRadon } from "../Station/Radon/DataAQMSRadon";

import earth from "../../imgAVIF/earth.avif";
import about from "../../imgAVIF/Rectangle-4.avif";
import wave from "../../imgAVIF/wave.avif";

const Item = styled(Paper)(({ theme }) => ({
  width: "230px",
  height: "110px",
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  color: "#202c38",
  boxShadow: "8px 10px 18px rgba(5, 4, 38, 0.503)",
}));

const dataPage = [
  {
    icon: TbHeartRateMonitor,
    title: "Real Time Monitoring",
    desc: "We Provide validated and the most updated data. You could monitor all the parameters hourly, daily, and even mothly!",
  },
  {
    icon: TiWarning,
    title: "Early Warning System",
    desc: "Only by sending your email, we will remind you if the air pollution or radon concentration around you is way too high to be safe!",
  },
  {
    icon: BsFillCloudHaze2Fill,
    title: "Forecast Air and Radon Quality",
    desc: "Using the AI technology, we could provide the forcested data so you could know what would happen tomorrow!",
  },
];

function Home() {
  const navigate = useNavigate();
  const data = dataAQMSRadon();
  const [res, setRes] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const url = `https://biru-langit.com/api/TULT/24H`;
      const response = await axios.get(url);
      setRes(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <section className="section-top bg" style={{ position: "relative" }}>
        <Container sx={{ pt: 15, pb: 25 }} maxWidth="md">
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={5}
              xl={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={earth}
                alt="earth"
                style={{
                  maxHeight: "320px",
                  maxWidth: "100%",
                }}
                width="auto"
                height="100%"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={7}
              lg={7}
              xl={4}
              sx={{ p: 3 }}
              className="sm-center"
            >
              <p>integrated with Microsensors</p>
              <h1>
                Monitor The <span>Air</span> and{" "}
                <span>Environmental Radiation</span> with Us!
              </h1>

              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  minWidth: "270px",
                  fontSize: "18px",
                  fontWeight: "700",
                  letterSpacing: "3px",
                }}
                startIcon={<BsSearch />}
                onClick={() => navigate("/our-station")}
              >
                Monitor Now!
              </Button>
            </Grid>
          </Grid>
        </Container>
        <img
          src={wave}
          alt="wave"
          width="100%"
          height="auto"
          style={{ position: "absolute", bottom: "0" }}
        />
      </section>

      <section className="our-features" id="ourFeatures">
        <Container sx={{ py: 3 }} maxWidth="md">
          <h2>Our Features</h2>
          <p
            style={{
              paddingTop: "2px",
              paddingBottom: "50px",
              letterSpacing: "2px",
            }}
          >
            You definitely should start monitoring the air and radon quality
            with Biru Langit's cool features!
          </p>
          <Box sx={{ flexGrow: 2 }}>
            <Grid
              container
              spacing={2}
              columns={{ xs: 4, sm: 12, md: 12 }}
              justifyContent="center"
            >
              {dataPage.map((item, index) => (
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={8}
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Item>
                    <item.icon style={{ fontSize: "70px" }} />
                    <h3>{item.title}</h3>
                  </Item>
                  <p style={{ padding: "17px" }}>{item.desc}</p>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </section>
      <section className="about-anr" id="aboutUs">
        <Container maxWidth="lg" sx={{ pb: 5, pt: 5 }}>
          <div className="box">
            <Grid container justifyContent="center">
              <Grid className="map-home" item lg={7} md={12} sm={12} xs={12}>
                {/* <Container padding="10px">  */}
                <Map
                  res={res}
                  data={data}
                  style={{ backgroundImage: `url(${about})` }}
                />
                {/* </Container> */}
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12} sx={{ px: 7, py: 5 }}>
                <h2>Air Around You!</h2>
                <p>
                  Created using up-to-date air pollution data and Air Quality
                  Index (AQI) calculations, this heatmap is a powerful visual
                  tool to understand the level of air contamination around you.
                  AQI is the standard measure used to evaluate air quality based
                  on major pollutants such as PM2.5 and CO2.
                </p>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    width: "300px",
                    fontSize: "16px",
                    fontWeight: "500",
                    letterSpacing: "3px",
                  }}
                  endIcon={<BsFillArrowRightCircleFill />}
                  onClick={() => navigate("/heatmap")}
                >
                  See more heatmap
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>

      <section className="about-anr" id="aboutUs">
        <Container maxWidth="lg" sx={{ pb: 10, pt: 5 }}>
          <div className="box">
            <Grid container justifyContent="center" className="sm-reverse">
              <Grid item lg={7} md={7} sm={12} xs={12} sx={{ px: 7, py: 5 }}>
                <h2>What is Environmental Pollution?</h2>
                <p>
                  Environmental pollution is the act of introducing hazardous
                  substances and pollutants into the environment, which can
                  result in detrimental effects on living organisms and the
                  natural surroundings. Pollution can manifest in various forms,
                  including but not limited to air pollution, water pollution,
                  soil pollution, and noise pollution.
                </p>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    width: "200px",
                    fontSize: "16px",
                    fontWeight: "500",
                    letterSpacing: "3px",
                  }}
                  endIcon={<BsFillArrowRightCircleFill />}
                  onClick={() => navigate("/about-air-and-radon")}
                >
                  Learn More
                </Button>
              </Grid>

              <Grid
                className="image round-right"
                item
                lg={5}
                md={5}
                sm={12}
                xs={12}
                style={{
                  backgroundImage: `url(${about})`,
                  minHeight: "150px",
                }}
              ></Grid>
            </Grid>
          </div>
        </Container>
      </section>

      <section style={{ backgroundColor: "#152b56", color: "#152b56" }}>
        <Container sx={{ pt: 5 }} maxWidth="lg">
          <Grid container justifyContent="center" spacing={5}>
            <Grid item md={5.5} sm={8}>
              <Grid
                container
                justifyContent="center"
                sx={{ textAlign: "center" }}
                className="box"
              >
                <Grid
                  item
                  md={12}
                  className="early-warn"
                  style={{
                    backgroundImage: `url(${about})`,
                  }}
                />
                <Grid item md={12} sx={{ p: 3, px: 5 }}>
                  <h3>About INSIGHT Lab</h3>
                  <p>
                    INSIGHT Lab is a laboratory that primarily focuses on
                    research related to air and environmental quality utilizing
                    microsensors.
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5.5} sm={8}>
              <Grid
                container
                justifyContent="center"
                sx={{ textAlign: "center" }}
                className="box"
              >
                <Grid
                  item
                  md={12}
                  className="early-warn"
                  style={{
                    backgroundImage: `url(${about})`,
                  }}
                />
                <Grid item md={12} sx={{ p: 3, px: 5 }}>
                  <h3>About Biru Langit</h3>
                  <p>
                    Biru Langit is a startup operating in the field of air
                    quality monitoring, striving to create clean and healthy air
                    in Indonesia.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
}

export default Home;
