import React, { useState, useEffect } from "react";
import { tokens } from "../../Components/Dashboard/ThemeDashboard";
// import Logo from "./TU-logogram-238x300.jpg"; // Import file gambar lokal
import {
  useTheme,
  Box,
  Button,
  Container,
  Select,
  MenuItem,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { ApiRounded } from "@mui/icons-material";

const DataHistory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedDataType, setSelectedDataType] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  const [selectedDataPeriod, setSelectedDataPeriod] = useState("");
  const [selectedPollutant, setSelectedPollutant] = useState("");
  const [selectedCompliancestd, setSelectedCompliancestd] = useState("");
  const [selectedCompliancecv, setSelectedCompliancecv] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [popupOpen, setPopupOpen] = useState(true);
  const [downloadFormat, setDownloadFormat] = useState("pdf"); // Add this state

  const DataType = ["Data Raw", "Data validated", "Data History"];
  const Stations = ["Deli", "GKU", "TULT","Tamansari"];//tambah disini juga stasiunnya
  const DataPeriod = [
    "2 min",
    "Hourly",
    "8 Hour",
    "24 Hour",
    "Weekly",
    "Monthly",
    "Annually",
  ];
  const Pollutant = ["PM2.5", "CO2", "All"];
  const Compliancestd = ["C", "NC", "All"];
  const Compliancecv = ["C", "NC", "All"];
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const handleChangeDataType = (event) => {
    setSelectedDataType(event.target.value);
    setSelectedCompliancestd("All") && setSelectedCompliancecv("All");
  };

  const handleChangeStation = (event) => {
    setSelectedStation(event.target.value);
  };

  const handleChangeDataPeriod = (event) => {
    setSelectedDataPeriod(event.target.value);
  };

  const handleChangePollutant = (event) => {
    setSelectedPollutant(event.target.value);
  };
  const handleChangeCompliancestd = (event) => {
    setSelectedCompliancestd(event.target.value);
  };
  const handleChangeCompliancecv = (event) => {
    setSelectedCompliancecv(event.target.value);
  };

  const handleApplyFilter = async () => {
    if (!selectedStation) {
      alert("Please select all filter criteria before applying the filter.");
      return;
    }
  
    setOpen(true);
    let apiUrl = `https://biru-langit.com/api/${selectedStation}`;
  
    if (selectedDataType === "Data validated") {
      apiUrl += `/${
        selectedDataPeriod === "Annually"
          ? "Year"
          : selectedDataPeriod === "Monthly"
          ? "Month"
          : selectedDataPeriod === "Weekly"
          ? "Week"
          : selectedDataPeriod === "24 Hour"
          ? "24H"
          : selectedDataPeriod === "8 Hour"
          ? "8H"
          : selectedDataPeriod === "Hourly"
          ? "1H"
          : selectedDataPeriod === "2 min"
          ? "2m"
          : ""
      }`;
    } else {
      apiUrl += "/raw";
    }
  
    if (selectedDataPeriod !== "Annually") {
      if (startDate && endDate) {
        const startDateISOString = startDate.toISOString();
        const endDateISOString = endDate.toISOString();
        apiUrl += `?time_low=${startDateISOString}&time_high=${endDateISOString}`;
      }
    }
  
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }
  
      // Check if response is JSON
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Received non-JSON response");
      }
  
      const rawData = await response.json();
      let filteredData = rawData;
  
      if (selectedDataType !== "Data validated" && selectedDataPeriod !== "Annually") {
        if (selectedDataType !== "Data Raw") {
          if (selectedCompliancestd !== "All") {
            filteredData = filteredData.filter(
              (item) => item["Compliance STD"] === selectedCompliancestd
            );
          }
  
          if (selectedCompliancecv !== "All") {
            filteredData = filteredData.filter(
              (item) => item["Compliance CV"] === selectedCompliancecv
            );
          }
        }
      }
      setFilteredData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOpen(false);
    }
    setPopupOpen(false);
  };
  

  const downloadPDF = () => {
    const doc = new jsPDF("landscape");
    const tableNode = document.getElementById("data-table");

    // Tambahkan margin untuk memindahkan tabel ke bawah
    const margin = 0;

    // Teks "Data History" diganti dengan nilai dari selectedStation
    const selectedStationText = selectedStation
      ? `Data history of ${selectedStation}`
      : "Data History";
    const selectedPollutantText = selectedPollutant
      ? `Parameter polutan: ${selectedPollutant}`
      : "";
    const selectedDataTypeText = selectedDataType
      ? `Data type: ${selectedDataType}`
      : "";
    const dataOwnerText =
      "Data ini milik INSIGHT Laboratorium Teknik Lingkungan \nFakultas Teknik Elektro - Telkom University, Bandung";

    const styles = {
      fontSize: 8, // Ukuran font di dalam tabel
      cellPadding: 1, // Padding antar sel di dalam tabel
      halign: "center",
    };

    const columnStyles = {
      id: { fontSize: 8, halign: "center" },
      date: { fontSize: 8, halign: "center" }, // Teks di tengah untuk kolom tanggal
      temperature: { fontSize: 8, halign: "center" },
      humidity: { fontSize: 8, halign: "center" },
      co2: { fontSize: 8, halign: "center", cellWidth: 20 },
      pm25: { fontSize: 8, halign: "center" },
      std_pm25: { fontSize: 8, halign: "center" },
      "Compliance STD": { fontSize: 8, halign: "center" },
      cv_pm25: { fontSize: 8, halign: "center" },
      "Compliance CV": { fontSize: 8, halign: "center" },
      ws: { fontSize: 8, halign: "center" },
      wd: { fontSize: 8, halign: "center" },
    };
    doc.setFontSize(12);
    doc.text(selectedStationText, 15, 15); // Teks sesuai dengan nilai selectedStation pada koordinat (15, 15)
    doc.setFontSize(10);
    doc.text(selectedPollutantText, 15, 20);
    doc.text(selectedDataTypeText, 15, 25);
    doc.setFontSize(8);
    doc.text(dataOwnerText, 15, 33);
    doc.autoTable({
      html: tableNode,
      startY: 40 + margin,
      styles: styles,
      columnStyles: columnStyles,
    }); // Tambahkan margin dan mulai tabel di bawah teks
    doc.save("data_history.pdf");
  };
  const downloadExcel = () => {
    const tableNode = document.getElementById("data-table");
  
    const workbook = XLSX.utils.book_new();
    const worksheetData = [];
  
    // Mendapatkan data dari tabel HTML
    const rows = tableNode.querySelectorAll("tr");
  
    rows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("th, td");
  
      cells.forEach((cell) => {
        rowData.push(cell.innerText);
      });
  
      worksheetData.push(rowData);
    });
  
    // Membuat worksheet dari data yang sudah dikumpulkan
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data History");
  
    // Mengunduh file Excel
    XLSX.writeFile(workbook, "data_history.xlsx");
  };
  const handleDownload = () => {
    if (downloadFormat === "pdf") {
      downloadPDF();
    } else if (downloadFormat === "excel") {
      downloadExcel();
    }
  };
  



  useEffect(() => {
    // Do not fetch data initially, wait for the user to select filters
  }, []);

  return (
    <div>
      <Dialog
        open={popupOpen}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Silahkan Memilih Filter Data Terlebih Dahulu"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sebelum melanjutkan, harap pilih filter data yang ingin digunakan.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Container sx={{ pb: 10 }}>
        <Typography variant="h1" textAlign="left">
          Data History
        </Typography>
        <Typography variant="subtitle1" textAlign="left" marginBottom={"10px"}>
          Data History of Raw and Validated Data
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "left",
            marginBottom: "10px",
          }}
        >
          <FormControl
            variant="outlined"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel>Data Type</InputLabel>
            <Select
              value={selectedDataType}
              onChange={handleChangeDataType}
              label="Data Type"
            >
              {DataType.map((DataType, index) => (
                <MenuItem key={index} value={DataType}>
                  {DataType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel>Station</InputLabel>
            <Select
              value={selectedStation}
              onChange={handleChangeStation}
              label="Station"
            >
              {Stations.map((station, index) => (
                <MenuItem key={index} value={station}>
                  {station}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedDataType === "Data validated" && (
            <FormControl
              variant="outlined"
              sx={{ minWidth: 120, marginRight: "10px" }}
            >
              <InputLabel>Data Period</InputLabel>
              <Select
                value={selectedDataPeriod}
                onChange={handleChangeDataPeriod}
                label="Data Period"
              >
                {DataPeriod.map((DataPeriod, index) => (
                  <MenuItem key={index} value={DataPeriod}>
                    {DataPeriod}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl
            variant="outlined"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel>Pollutant</InputLabel>
            <Select
              value={selectedPollutant}
              onChange={handleChangePollutant}
              label="Pollutant"
            >
              {Pollutant.map((Pollutant, index) => (
                <MenuItem key={index} value={Pollutant}>
                  {Pollutant}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedDataType === "Data validated" &&
            selectedDataPeriod === "24 Hour" && (
              <>
                <FormControl
                  variant="outlined"
                  sx={{ minWidth: 150, marginRight: "10px" }}
                >
                  <InputLabel>Compliance STD</InputLabel>
                  <Select
                    value={selectedCompliancestd}
                    onChange={handleChangeCompliancestd}
                    label="Compliance STD"
                  >
                    {Compliancestd.map((Compliancestd_c, index) => (
                      <MenuItem key={index} value={Compliancestd_c}>
                        {Compliancestd_c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" sx={{ minWidth: 150 }}>
                  <InputLabel>Compliance CV</InputLabel>
                  <Select
                    value={selectedCompliancecv}
                    onChange={handleChangeCompliancecv}
                    label="Compliance CV"
                  >
                    {Compliancecv.map((Compliancecv_c, index) => (
                      <MenuItem key={index} value={Compliancecv_c}>
                        {Compliancecv_c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
                 <FormControl
            variant="outlined"
            sx={{ minWidth: 120, marginRight: "10px" }}
          >
            <InputLabel>Download Format</InputLabel>
            <Select
              value={downloadFormat}
              onChange={(e) => setDownloadFormat(e.target.value)}
              label="Download Format"
            >
              <MenuItem value="pdf">PDF</MenuItem>
              <MenuItem value="excel">Excel</MenuItem>
            </Select>
          </FormControl>


          <TextField
            label="Start Date"
            type="date"
            value={startDate.toISOString().split("T")[0]}
            onChange={(e) => {
              const localDate = new Date(e.target.value);
              localDate.setDate(localDate.getDate()); // kurang 1 hari karena perbedaan waktu UTC
              setStartDate(localDate);
            }}
            style={{ marginRight: "10px", marginLeft: "10px" }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate.toISOString().split("T")[0]}
            onChange={(e) => {
              const localDate = new Date(e.target.value);
              localDate.setDate(localDate.getDate()); // kurang 1 hari karena perbedaan waktu UTC
              setEndDate(localDate);
            }}
          />
        </div>
        <Box
          p="10px"
          bgcolor={colors.primary[400]}
          color={colors.grey[100]}
          overflow="auto"
          gridColumn="span 1"
          gridRow="span 2"
        >
          <Typography variant="subtitle2" marginBottom="5px">
            Total entries: {filteredData.length}
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 500, overflow: "auto" }}
          >
            <Table id="data-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    No
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Tanggal
                  </TableCell>
                  {selectedPollutant !== "All" && (
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {selectedPollutant}
                    </TableCell>
                  )}
                  {selectedPollutant === "All" && (
                    <>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        CO2
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        PM2.5
                      </TableCell>
                    </>
                  )}

                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    T Chamber
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Rh Chamber
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    T Shelter
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Rh Shelter
                  </TableCell>
                  {(selectedDataType === "Data Raw" ||
                    selectedDataPeriod === "2 min") && (
                    <>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Wind Speed (WS)
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Wind Direction (WD)
                      </TableCell>
                    </>
                  )}
                  {selectedDataType === "Data validated" &&
                    selectedDataPeriod === "24 Hour" && (
                      <>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          STD
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          STD Comp
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          CV
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          CV Comp
                        </TableCell>
                      </>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Loop through each data */}
                {filteredData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {selectedDataPeriod === "Annually"
                        ? item.year
                        : item.created_at}
                    </TableCell>{" "}
                    {selectedPollutant === "CO2" && (
                      <TableCell align="center">
                        {item.co2 === null || isNaN(parseFloat(item.co2))
                          ? "NaN"
                          : `${parseFloat(item.co2).toFixed(2)} ppm`}
                      </TableCell>
                    )}
                    {selectedPollutant === "PM2.5" && (
                      <TableCell align="center">
                        {item.pm25 === null || isNaN(parseFloat(item.pm25))
                          ? "NaN"
                          : `${parseFloat(item.pm25).toFixed(2)} µg/m³`}
                      </TableCell>
                    )}
                    {selectedPollutant === "All" && (
                      <>
                        <TableCell align="center">
                          {item.co2 === null || isNaN(parseFloat(item.co2))
                            ? "NaN"
                            : `${parseFloat(item.co2).toFixed(2)} ppm`}
                        </TableCell>
                        <TableCell align="center">
                          {item.pm25 === null || isNaN(parseFloat(item.pm25))
                            ? "NaN"
                            : `${parseFloat(item.pm25).toFixed(2)} µg/m³`}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center">
                      {item.temperature === null ||
                      isNaN(parseFloat(item.temperature))
                        ? "NaN"
                        : `${parseFloat(item.temperature).toFixed(2)} °C`}
                    </TableCell>
                    <TableCell align="center">
                      {item.humidity === null ||
                      isNaN(parseFloat(item.humidity))
                        ? "NaN"
                        : `${parseFloat(item.humidity).toFixed(2)}%`}
                    </TableCell>
                    <TableCell align="center">
                      {item.sht31_temp === null ||
                      isNaN(parseFloat(item.sht31_temp))
                        ? "NaN"
                        : `${parseFloat(item.sht31_temp).toFixed(2)} °C`}
                    </TableCell>
                    <TableCell align="center">
                      {item.sht31_hum === null ||
                      isNaN(parseFloat(item.sht31_hum))
                        ? "NaN"
                        : `${parseFloat(item.sht31_hum).toFixed(2)}%`}
                    </TableCell>
                    {(selectedDataType === "Data Raw" ||
                      selectedDataPeriod === "2 min") && (
                      <>
                        <TableCell align="center">
                          {item.ws === null || isNaN(parseFloat(item.ws))
                            ? "NaN"
                            : `${parseFloat(item.ws).toFixed(2)} m/s`}
                        </TableCell>
                        <TableCell align="center">
                          {item.wd === null || isNaN(parseFloat(item.wd))
                            ? "NaN"
                            : `${parseFloat(item.ws).toFixed(2)}`}
                        </TableCell>
                      </>
                    )}
                    {selectedDataType === "Data validated" &&
                      selectedDataPeriod === "24 Hour" && (
                        <>
                          <TableCell align="center">
                            {item.std_pm25 === null ||
                            item.std_pm25 === undefined
                              ? "NaN"
                              : item.std_pm25.toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            {item["Compliance STD"] === null ||
                            item["Compliance STD"] === undefined
                              ? "NaN"
                              : item["Compliance STD"]}
                          </TableCell>
                          <TableCell align="center">
                            {item.cv_pm25 === null || item.cv_pm25 === undefined
                              ? "NaN"
                              : item.cv_pm25.toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            {item["Compliance CV"] === null ||
                            item["Compliance CV"] === undefined
                              ? "NaN"
                              : item["Compliance CV"]}
                          </TableCell>
                        </>
                      )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
            style={{ marginRight: "10px", marginTop: "10px" }}
          >
            Apply Filter
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            style={{ marginTop: "10px", marginRight: "10px" }}
          >
            Download Data History
          </Button>
        </div>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DataHistory;
