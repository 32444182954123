import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import "leaflet/dist/leaflet.css";

const theme = createTheme({
  shape: {
    borderRadius: "20px",
    border: "2px",
  },
  palette: {
    primary: {
      main: "#163461",
      dark: "#2B5AA1",
    },
    // secondary: {
    //   main: "#93201a",
    //   dark: "#314430",
    // },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      lg: 1200,
      md: 1020,
      sm: 700,
      xs: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
