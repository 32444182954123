import StationAQMSTULT from "../Pages/Station/AQMSTULT/StationAQMSTULT";
import StationAcid from "../Pages/Station/AcidGKU/StationAcid";
import StationAcidTULT from "../Pages/Station/AcidTULT/StationAcidTULT";
import StationRadon from "../Pages/Station/Radon/StationRadon";
import StationAQMSDeli from "../Pages/Station/AQMSDeli/StationAQMSDeli";
import StationAQMSGKU from "../Pages/Station/AQMSGKU/StationAQMSGKU";
import StationTower from "../Pages/Station/Tower/StationTower";
import StationAQMSTamansari from "../Pages/Station/AQMSTamansari/StationAQMSTamansari";
import AllStationAQMS from "../Pages/Station/AQMSAll/AllStationAQMS";

const StationList = () => {
  return [
    {
      name: "TelU-All Station (AQMS)",
      parameter: "PM2.5, CO2,  Rh Chamber, T Chamber, Wind Speed, Wind Direction",
      url: "/all-station",
      element: <AllStationAQMS />,
    },
    {
      name: "TelU-GKU (AQMS)",
      parameter: "PM2.5, CO2, Rh Shelter, T Shelter, Rh Chamber, T Chamber, Wind Speed, Wind Direction",
      url: "/station-GKU",
      element: <StationAQMSGKU />,
    },
    {
      name: "TelU-Deli (AQMS)",
      parameter: "PM2.5, CO2, Rh Shelter, T Shelter, Rh Chamber, T Chamber, Wind Speed, Wind Direction",
      url: "/station-Deli",
      element: <StationAQMSDeli />,
    },
    {
      name: "TelU-TULT (AQMS)",
      parameter: "PM2.5, CO2, Rh Shelter, T Shelter, Rh Chamber, T Chamber, Wind Speed, Wind Direction",
      url: "/station-TULT",
      fetchUrl: `https://biru-langit.com/api/acidrainstults/data`,
      element: <StationAQMSTULT />,
    },
    {
      name: "TelU-Tamansari (AQMS)",
      parameter: "PM2.5, CO2, Rh Shelter, T Shelter, Rh Chamber, T Chamber, Wind Speed, Wind Direction",
      url: "/station-Tamansari",
      fetchUrl: `http://biru-langit.com/api/SW21/Tamansari/2m`,
      element: <StationAQMSTamansari />,
    },
    {
      name: "TelU (Radon)",
      parameter: "CO2, PM2.5, Geiger(Radon), Photodioda (Radon) (Under maintenance)",
      url: "/station-radon",
      fetchUrl: `https://biru-langit.com/api/radons/data`,
      element: <StationRadon />,
    },
    {
      name: "TelU-GKU (Acid Rain)",
      parameter: "pH, Temperature, Conductivity, Rainfall (Acid Rain) (Under maintenance)",
      url: "/station-acid",
      fetchUrl: `https://biru-langit.com/api/acidrains/data`,
      element: <StationAcid />,
    },
    {
      name: "TelU-TULT (Acid Rain)",
      parameter: "pH, Temperature, Conductivity, Rainfall (Acid Rain)(Under maintenance)",
      url: "/station-acidTULT",
      fetchUrl: `https://biru-langit.com/api/acidrainstults/data`,
      element: <StationAcidTULT />,
    },
    {
      name: "TelU-Tower (AQMS)",
      parameter: "PM2.5, CO2, RH, T, Atmospheric pressure, WS, WD, Pitch, Roll (Under maintenance)",
      url: "/station-tower",
      fetchUrl: `https://biru-langit.com/api/towers/data/`,
      element: <StationTower />,
    },
  ];
};

export { StationList };
