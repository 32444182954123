//image
import emoteHijau from "../../../imgAVIF/emote_hijau.avif";
import emoteKuning from "../../../imgAVIF/emote_kuning.avif";
import emoteMerah from "../../../imgAVIF/emote_merah.avif";

function ParameterButton({ onClick, parameter, unit, value, name, high, low }) {
  const getEmoteImage = (value, high, low) => {
    if (value > high) return emoteMerah;
    else if (value >= low) return emoteKuning;
    else if (value < low) return emoteHijau;
    else if (value === null || value === undefined) return emoteMerah;
  };
  const shouldDisplayEmote = !["Pressure", "Temperature", "Humidity", "Temperature Shelter", "Humidity Shelter", "Temperature Shelter", "Humidity Shelter", "Wind Speed", "Wind Direction", "Pitch", "Roll"].includes(name);
  return (
    <>
      <button onClick={() => onClick(parameter, unit, high, low, name)}>
        <div className='button-container'>
          <div className='button-name'>{name}</div>
          <div className='button-value'>
            {value}
            <span style={{ fontSize: "12px" }}> {unit}</span>
          </div>
        </div>
        {shouldDisplayEmote && (
        <div className='emote'>
          <img src={getEmoteImage(value, high, low)} alt='EmoteVal' />
        </div>
      )}
      </button>
    </>
);
}

export default ParameterButton;
