import logo from "../../imgAVIF/kebencanaan.avif";
import "./Kebencanaan.scss";
import { Grid, Container } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";

function Kebencanaan() {
  return (
    <section className="bg">
      <Container maxWidth="md" sx={{ pt: 12, pb: 5 }}>
        <Grid container alignItems="center" className="xs-reverse">
          <Grid item xs={12} sm={6} md={6} lg={6} className="top-title">
            <h1>Articles</h1>
            <p>Discover more about environmental pollutions</p>
            <SearchBar />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="kebencanaan-logo"
              src={logo}
              alt="kebencanaan"
              style={{
                maxHeight: "350px",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Kebencanaan;
