export const windSources = [
  { value: 'val1', name: '0.0-3.75 m/s' },
  { value: 'val2', name: '3.75-7.5 m/s' },
  { value: 'val3', name: '7.5-11.25 m/s' },
  { value: 'val4', name: '11.25-15.0 m/s' },
  { value: 'val5', name: '15.0-18.75 m/s' },
  { value: 'val6', name: '18.75-22.5 m/s' },
  { value: 'val7', name: '22.5-26.25 m/s' },
  { value: 'val8', name: '26.25-30.0 m/s' },
];

  export const windRoseData = [
    {
      period: 'Feb. 1, 2024 -  Mar. 1, 2024',
      values: [
        {
          arg: 'N',
          val1: 0.7,
          val2: 1.7,
          val3: 2,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NNE',
          val1: 0.1,
          val2: 0.6,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NE',
          val1: 0.3,
          val2: 0.8,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'ENE',
          val1: 0.3,
          val2: 0.7,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'E',
          val1: 0.7,
          val2: 3.2,
          val3: 3,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'ESE',
          val1: 0.8,
          val2: 1.5,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SE',
          val1: 0.3,
          val2: 1.3,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SSE',
          val1: 0.1,
          val2: 2.4,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'S',
          val1: 1.1,
          val2: 4.2,
          val3: 2,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SSW',
          val1: 0.6,
          val2: 3.6,
          val3: 3,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SW',
          val1: 0.8,
          val2: 2.5,
          val3: 5,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'WSW',
          val1: 0.3,
          val2: 2.6,
          val3: 3,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'W',
          val1: 0.6,
          val2: 1.7,
          val3: 3,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'WNW',
          val1: 0.7,
          val2: 2.5,
          val3: 3,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NW',
          val1: 1,
          val2: 3.2,
          val3: 3,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NNW',
          val1: 0.6,
          val2: 3.8,
          val3: 4,
          val4: 2,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
      ],
    },
    {
      period: 'Mar. 1, 2024 -  Apr. 1, 2024',
      values: [
        {
          arg: 'N',
          val1: 0.6,
          val2: 1.8,
          val3: 2,
          val4: 1,
          val5: 0,
          val6: 1,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NNE',
          val1: 0.3,
          val2: 1.2,
          val3: 1,
          val4: 0,
          val5: 1,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NE',
          val1: 0.3,
          val2: 2.4,
          val3: 2,
          val4: 1,
          val5: 1,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'ENE',
          val1: 1,
          val2: 2.2,
          val3: 1,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'E',
          val1: 0.6,
          val2: 4.9,
          val3: 2,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'ESE',
          val1: 0.1,
          val2: 0.6,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SE',
          val1: 0.1,
          val2: 0.3,
          val3: 1,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SSE',
          val1: 0.4,
          val2: 0.7,
          val3: 1,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'S',
          val1: 0,
          val2: 3.1,
          val3: 3,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SSW',
          val1: 0.6,
          val2: 1.8,
          val3: 4,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SW',
          val1: 0.7,
          val2: 1.8,
          val3: 2,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'WSW',
          val1: 0.3,
          val2: 2.5,
          val3: 5,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'W',
          val1: 0,
          val2: 2.8,
          val3: 6,
          val4: 2,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'WNW',
          val1: 0.3,
          val2: 1.5,
          val3: 4,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NW',
          val1: 0.1,
          val2: 2.7,
          val3: 2,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NNW',
          val1: 0.3,
          val2: 1.5,
          val3: 1,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
      ],
    },
    {
      period: 'Apr. 1, 2024 -  May. 1, 2024',
      values: [
        {
          arg: 'N',
          val1: 0.7,
          val2: 3,
          val3: 8,
          val4: 2,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NNE',
          val1: 0.4,
          val2: 1.6,
          val3: 2,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NE',
          val1: 0.5,
          val2: 3.4,
          val3: 8,
          val4: 2,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'ENE',
          val1: 0.3,
          val2: 4.1,
          val3: 2,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'E',
          val1: 1.2,
          val2: 1.8,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'ESE',
          val1: 0.7,
          val2: 0.3,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SE',
          val1: 0.1,
          val2: 0.3,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SSE',
          val1: 0.3,
          val2: 0.4,
          val3: 1,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'S',
          val1: 0.4,
          val2: 0.8,
          val3: 1,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SSW',
          val1: 0.4,
          val2: 1.5,
          val3: 0,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'SW',
          val1: 0.8,
          val2: 0.1,
          val3: 1,
          val4: 0,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'WSW',
          val1: 0,
          val2: 1.5,
          val3: 2,
          val4: 1,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'W',
          val1: 0.3,
          val2: 1,
          val3: 6,
          val4: 3,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'WNW',
          val1: 0.3,
          val2: 1.2,
          val3: 3,
          val4: 1,
          val5: 1,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NW',
          val1: 0.3,
          val2: 0.7,
          val3: 5,
          val4: 2,
          val5: 0,
          val6: 0,
          val7: 0,
          val8: 0,
        },
        {
          arg: 'NNW',
          val1: 0.1,
          val2: 2.5,
          val3: 2,
          val4: 2,
          val5: 1,
          val6: 0,
          val7: 0,
          val8: 0,
        },
      ],
    },
  ];
  export const periodLabel = { 'aria-label': 'Period' };
  