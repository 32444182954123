import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import "./EarlyWarn.scss";
import img from "../../imgAVIF/Rectangle-4.avif";

export default function EarlyWarn() {
  const [email, setEmail] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch("https://biru-langit.com/csrf-token", {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch CSRF token");
        }
        const data = await response.json();
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hgajdk", email);
    const Data = {
      email: email,
    };
    setOpen(true);
    try {
      const response = await fetch("https://biru-langit.com/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "X-CSRF-TOKEN": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify(Data),
      });

      if (!response.ok) {
        throw new Error("Failed to subscribe");
      }

      console.log("Subscription successful");
      window.location.href = "/thank-you";
    } catch (error) {
      console.error("Error subscribing email:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
   <div
    className="bg" 
    style={{ background: "none" }} // Tambahkan ini untuk menghapus background
  >
      <Container sx={{ pt: 18, pb: 10 }} maxWidth="sm">
        <div className="box">
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid
              item
              md={12}
              className="early-warn"
              style={{
                backgroundImage: `url(${img})`,
              }}
            />
            <Grid item md={12} sm={12} xs={12} sx={{ p: 3 }}>
              <Typography component="h1" variant="h4">
                Get an Early Warning?
              </Typography>
              <Typography component="h5">
                Let's go and get an early warning!
              </Typography>

              <form onSubmit={handleSubmit}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />
                <p>*Make sure your email is correct!</p>
                <Button
                  type="submit"
                  disabled={!email}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Thank you for subscribing!</DialogTitle>
        <DialogContent>
          Please check your email to verify your subscription. If you do not see
          the email, check your spam folder.
        </DialogContent>
      </Dialog>
    </div>
  );
}
