import { Link } from "react-router-dom";
import { Grid, Container} from "@mui/material";
import "./Footer.scss";
import logo from "../../imgAVIF/Logo.avif";
import telkom from "../../imgAVIF/logo_telu.avif";
import brin from "../../imgAVIF/logo_BRIN.avif";
import dikti from "../../imgAVIF/logo_dikti.avif";
import utp from "../../imgAVIF/logo_UTP.avif";
import kanazawa from "../../imgAVIF/logo_kanazawa.avif";

function Footer() {
  return (
    <div>
      <section className='bottom'>
        <Container sx={{ py: 6 }} maxWidth='md'>
          <Grid container justifyContent='space-evenly'>
            <Grid item md={3} sm={7} xs={10}>
              <Link to='/'>
                <img
                  className='logo'
                  src={logo}
                  alt='Biru Langit'
                  width='200px'
                />
                <p className='span-logo' style={{ color: "white" }}>
                  by INSIGHT Laboratorium
                </p>
              </Link>
              <p style={{ paddingTop: "20px" }}>
                Striving to create clean and healthy air in Indonesia.
              </p>
            </Grid>
            <Grid item md={2.5} sm={7} xs={10} className='coba'>
              <div className='navigations'>
                <h4>Navigation</h4>
                <Link to='/publication' className='link'>
                  Publication
                </Link>
                <Link to='/about-air-and-radon' className='link'>
                  About Air and Radon Quality
                </Link>
                <Link to='/get-early-warning' className='link'>
                  Get Early Warning
                </Link>
              </div>
            </Grid>
            <Grid item md={3} sm={7} xs={10} className='navigations'>
              <h4>Find Us</h4>
              <iframe
                style={{ borderRadius: "13px" }}
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7920.619049855791!2d107.63164248465851!3d-6.972762061906384!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e9adf177bf8d%3A0x437398556f9fa03!2sTelkom%20University!5e0!3m2!1sen!2sid!4v1679280202802!5m2!1sen!2sid&z=-10'
                title='Alamat'
                width='100%'
                height='100px'
                loading='lazy'
              ></iframe>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section>
        <Container sx={{ py: 1, textAlign: 'center' }} maxWidth='md'>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <h3>In collaboration with :</h3>
            </Grid>
            <Grid item xs={12} className="logos">
              <img className='logo-partner' src={telkom} alt='Telkom' />
              <img className='logo-partner' src={brin} alt='BRIN' />
              <img className='logo-partner' src={dikti} alt='DIKTI' />
              <img className='logo-partner' src={utp} alt='UTP' />
              <img className='logo-partner' src={kanazawa} alt='Kanazawa' />
            </Grid>
          </Grid>
        </Container>
      </section>
      <footer>
        <p>
          Copyright © INSIGHT Laboratory{" "}
          {new Date().getFullYear()} .
        </p>
      </footer>
    </div>
  );
}

export default Footer;
