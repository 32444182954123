import React, { useEffect, useState, useCallback } from "react";
import "./StationRadon.scss";
import { Container } from "@mui/material";
// import Map from "../../../Components/Map/Map";
import axios from "axios";
import ForecastTable from "../../../Components/forecastTable/forecastTable";
import ChartAQMSRadon from "./ChartAQMSRadon";
import { dataAQMSRadon } from "./DataAQMSRadon";
import App from "../../../Components/Windrose/Windrose";

function StationRadon() {
  const data = dataAQMSRadon();
  const [res, setRes] = useState(null);
  const fetchData = useCallback(async () => {
    try {
      const url = `https://biru-langit.com/api/radons/valids/data`;
      const response = await axios.get(url);
      setRes(response);
      // console.log(response);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors here, you might want to set an error state
    }
  }, []); // Empty dependency array means it won't be recreated on re-renders


  useEffect(() => {
    // const fetchData = async () => {
    //   // Fetch data based on the parameter state
    //   const url = `https://biru-langit.com/api/radons/valids/data`;
    //   const response = await axios.get(url); 
    //   setRes(response);
    //   // console.log(response);
    // };
    fetchData();
  }, [fetchData]);
  return (
    <div>
      <section className="bg">
        <Container sx={{ pt: 12, pb: 5 }} maxWidth="md">
          <ChartAQMSRadon res={res}/>
        </Container>
      </section>
      <div style={{  backgroundColor: "#152b56", display: "flex", justifyContent: "center" }}>
      <App/>
      <ForecastTable />
      </div>

      {/* <Map res={res} data={data}/> */}
    </div>
  );
}

export default StationRadon;
