import React, { useEffect, useState, useCallback } from "react";
import Map from "../../Components/Map/Map";
import { Card, Container, Grid } from "@mui/material";
import axios from "axios";
import { dataHeatmapTult } from "../Station/AQMSTULT/DataAQMSTult";
// import "./Heatmap.scss"
import wav from "../../imgAVIF/wave.avif";

function HeatMap() {
  const data = dataHeatmapTult();
  const [res, setRes] = useState(null);
  const fetchData = useCallback(async () => {
    try {
      const url = `https://biru-langit.com/api/TULT/2m`;
      const response = await axios.get(url);
      setRes(response);
      // console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors here, you might want to set an error state
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div>
       <section className="section-top bg" style={{ position: "relative" }}>
       <Container sx={{ pt: 25, pb: 15 }} maxWidth="md">
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={5}
              xl={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
            <img
              src={wav}
              alt="wave"
              width="100%"
              height="auto"
              style={{ position: "absolute", bottom: "0" }}
            />
            </Grid>
          </Grid>
        </Container>
      </section>
     
      <Map res={res} data={data} />
      {/* <section className="bg"> </section> */}

    </div>
  );
}

export default HeatMap;
