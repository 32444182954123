// Windrose.jsx
import React from "react";
import {
  PolarChart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  ValueAxis,
  Margin,
} from "devextreme-react/polar-chart";
import Container from "@mui/material/Container";

// Fungsi untuk mengubah derajat arah angin menjadi arah kompas
const directionFromDegrees = (deg) => {
  if (0 <= deg && deg < 22.5 || 337.5 <= deg && deg <= 360) return 'N';
  if (22.5 <= deg && deg < 67.5) return 'NE';
  if (67.5 <= deg && deg < 112.5) return 'E';
  if (112.5 <= deg && deg < 157.5) return 'SE';
  if (157.5 <= deg && deg < 202.5) return 'S';
  if (202.5 <= deg && deg < 247.5) return 'SW';
  if (247.5 <= deg && deg < 292.5) return 'W';
  if (292.5 <= deg && deg < 337.5) return 'NW';
};

// Fungsi untuk mengelompokkan data arah angin berdasarkan kecepatan
const groupWindData = (data) => {
  const groupedData = {
    N: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    NE: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    E: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    SE: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    S: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    SW: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    W: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
    NW: { calm: 0, lightAir: 0, lightBreeze: 0, gentleBreeze: 0, moderateBreeze: 0, freshBreeze: 0, strongBreeze: 0, highWind: 0 },
  };

  data.forEach((item) => {
    const direction = directionFromDegrees(item.wd);
    let speedCategory;

    // Pengelompokan kecepatan angin sesuai skala Beaufort
    if (item.ws <= 0.5) speedCategory = 'calm';
    else if (item.ws <= 1.5) speedCategory = 'lightAir';
    else if (item.ws <= 3) speedCategory = 'lightBreeze';
    else if (item.ws <= 5) speedCategory = 'gentleBreeze';
    else if (item.ws <= 8) speedCategory = 'moderateBreeze';
    else if (item.ws <= 10.5) speedCategory = 'freshBreeze';
    else if (item.ws <= 13.5) speedCategory = 'strongBreeze';
    else speedCategory = 'highWind';

    if (direction) {
      groupedData[direction][speedCategory]++;
    }
  });

  return Object.keys(groupedData).map((arg) => ({ arg, ...groupedData[arg] }));
};

const WindroseGKU = ({ windData }) => {
  const windroseData = groupWindData(windData);

  return (
    <div style={{ backgroundColor: "#152b56" }}>
      <Container
        maxWidth="sm"
        sx={{ pt: 10, textAlign: "center", color: "white" }}
      >
        <h2 style={{ letterSpacing: "3px", fontSize: "32px" }}>Windrose</h2>
        <p
          style={{
            paddingTop: "2px",
            paddingBottom: "30px",
            paddingLeft: "10px",
            paddingRight: "10px",
            letterSpacing: "2px",
          }}
        >
          Explore the prevailing wind directions and intensities in your area!
        </p>
      </Container>
      <div
        id="chart-demo"
        style={{
          backgroundColor: "#ffffff",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <PolarChart
          id="radarChart"
          palette="Soft"
          dataSource={windroseData}
          style={{ width: "100%" }}
        >
          <CommonSeriesSettings type="stackedbar" />
          <Series valueField="calm" name="Calm (0.0-0.5 m/s)" />
          <Series valueField="lightAir" name="Light Air (0.5-1.5 m/s)" />
          <Series valueField="lightBreeze" name="Light Breeze (1.6-3 m/s)" />
          <Series valueField="gentleBreeze" name="Gentle Breeze (3.1-5 m/s)" />
          <Series valueField="moderateBreeze" name="Moderate Breeze (5.1-8 m/s)" />
          <Series valueField="freshBreeze" name="Fresh Breeze (8.1-10.5 m/s)" />
          <Series valueField="strongBreeze" name="Strong Breeze (10.6-13.5 m/s)" />
          <Series valueField="highWind" name="High Wind (>13.5 m/s)" />
          <Margin bottom={10} left={10} />
          <ArgumentAxis
            discreteAxisDivisionMode="crossLabels"
            firstPointOnStartAngle={true}
          />
          <ValueAxis valueMarginsEnabled={false} />
        </PolarChart>
      </div>
    </div>
  );
};

export default WindroseGKU;
