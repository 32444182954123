import { BarChart } from "@mui/x-charts";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import { useState } from "react";

// Function to generate dummy data based on parameters
const generateDummyData = (count, max, step) => {
  return Array.from({ length: count }, (_, i) => ({
    country: `Country ${i + 1}`,
    visits: Math.floor(Math.random() * (max / step)) * step,
  }));
};

// Dummy Data for different time filters
const oneHourData = [
  { country: "Indonesia", visits: 50 },
  { country: "United States", visits: 70 },
  { country: "India", visits: 65 },
  { country: "Australia", visits: 30 },
];
const sixHourData = generateDummyData(7, 3000, 500);
const dailyData = generateDummyData(7, 10000, 1000);
const weeklyData = generateDummyData(7, 71000, 10000);

const BarChartVisit = () => {
  const [timeFilter, setTimeFilter] = useState("Last One Hour");

  const handleTimeFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  // Select data based on time filter
  const currentCountryData =
    timeFilter === "Last 7 Days"
      ? weeklyData
      : timeFilter === "Last 24 Hours"
      ? dailyData
      : timeFilter === "Last 6 Hours"
      ? sixHourData
      : oneHourData;

  const maxCountryVisits = Math.max(...currentCountryData.map((data) => data.visits));

  return (
    <Box p={2} style={{ backgroundColor: "#D6E6F2", borderRadius: "8px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h3" fontWeight="550" color="0000">
          Visit Distribution by Country
        </Typography>
        <Select
          value={timeFilter}
          onChange={handleTimeFilterChange}
          style={{ minWidth: 150, maxHeight: "30px" }}
          sx={{
            "& .MuiSelect-selectMenu": {
              padding: "10px 14px",
              fontSize: "14px",
            },
            "& .MuiInputBase-root": {
              fontSize: "14px",
            },
          }}
        >
          <MenuItem value="Last One Hour">Last One Hour</MenuItem>
          <MenuItem value="Last 6 Hours">Last 6 Hours</MenuItem>
          <MenuItem value="Last 24 Hours">Last 24 Hours</MenuItem>
          <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
        </Select>
      </Box>
      <BarChart
        xAxis={[
          { data: currentCountryData.map((data) => data.country), title: "Country", scaleType: "band" },
        ]}
        yAxis={[{ title: "Visits", domain: [0, maxCountryVisits] }]}
        series={[
          {
            data: currentCountryData.map((data) => data.visits),
            color: "#28A745",
            valueFormatter: (value) => `${value} visits`,
          },
        ]}
        height={220}
        margin={{ top: 50, bottom: 20, left: 70, right: 20 }}
      />
    </Box>
  );
};

export default BarChartVisit;

