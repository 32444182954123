const dataAQMSRadon = () => {
  return [
    {
      parameter: "co2",
      unit: "ppm",
      value: "buttonData.co2",
      name: "CO2",
      high: 1000,
      low: 800,
    },
    {
      parameter: "pm25",
      unit: "μg/m3",
      value: "buttonData.pm25",
      name: "PM2.5",
      high: 50,
      low: 10,
    },
    {
      parameter: "phtd",
      unit: "μSv/h",
      value: "buttonData.radon",
      name: "α-Radiation",
      high: 2.5,
      low: 0.5,
    },
    {
      parameter: "geiger",
      unit: "μSv/h",
      value: "buttonData.radiation",
      name: "βγ-Radiation",
      high: 2.5,
      low: 0.5,
    },
    {
      parameter: "sht31_temp",
      unit: "°C",
      value: "buttonData.temp",
      name: "Temperature",
      high: 35,
      low: 25,
    },
    {
      parameter: "sht31_humid",
      unit: "%",
      value: "buttonData.humid",
      name: "Humidity",
      high: 100,
      low: 80,
    },
  ];
};

export { dataAQMSRadon };
