import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import axios from "axios";
import thumbnail from "../../imgAVIF/Rectangle-4.avif"; // Tambahkan import untuk gambar thumbnail

export default function RegisterFlagging() {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await axios.post("https://biru-langit.com/api/flagging/register", {
        email: email,
        token: token,
      });

      if (response.data.success) {
        setSuccess("Registration successful! You will receive notifications.");
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setError("Invalid token provided. Please check your token.");
      } else {
        setError(err.response?.data?.message || "Registration failed.");
      }
    }
  };

  return (
    <div className="bg">
      <Container sx={{ pt: 20, pb: 10 }} maxWidth="md">
        <div className="box-auth">
          <Grid container justifyContent="center">
            <Grid
              className="plane-img"
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: 'cover', // Menyesuaikan ukuran gambar
                backgroundPosition: 'center', // Menempatkan gambar di tengah
              }}
            />
            <Grid item md={6} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 5,
                }}
              >
                <Typography component="h1" variant="h4">
                  Register for Flagging
                </Typography>
                <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    name="token"
                    label="Token"
                    id="token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                  />

                  {error && <Alert severity="error">{error}</Alert>}
                  {success && <Alert severity="success">{success}</Alert>}

                  <Button
                    type="submit"
                    disabled={!email || !token}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Register
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
