import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { HiMenu } from "react-icons/hi";
import logo from "../../imgAVIF/Logo.avif";

const DrawerComp = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="right"
        PaperProps={{
          sx: { width: "60%" },
        }}
      >
        <Link to="/" onClick={() => setOpen(!open)}>
          <Box sx={{ pl: 2, pt: 1, pb: 2 }}>
            <img className="logo" src={logo} alt="Biru Langit" width="200px" />
            <p className="span-logo">by INSIGHT Laboratorium</p>
          </Box>
        </Link>
        <Divider variant="middle" />

        <List>
          {/* <ListItem
            disablePadding
            component={Link}
            to="/info-kebencanaan"
            onClick={() => setOpen(!open)}
          >
            <ListItemButton>Articles</ListItemButton>
          </ListItem> */}
          <ListItem
            disablePadding
            component={Link}
            to="/publication"
            onClick={() => setOpen(!open)}
          >
            <ListItemButton>Publication</ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/about-air-and-radon"
            onClick={() => setOpen(!open)}
          >
            <ListItemButton>About Air and Radon Quality</ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/our-station"
            onClick={() => setOpen(!open)}
          >
            <ListItemButton>Our Station</ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/login"
            onClick={() => setOpen(!open)}
          >
            <ListItemButton>Performance Dashboard</ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/get-early-warning"
            onClick={() => setOpen(!open)}
          >
            <ListItemButton>Early Warning System</ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <HiMenu
        fontSize="40px"
        onClick={() => setOpen(!open)}
        style={{ color: "#202c38" }}
      />
    </div>
  );
};

export default DrawerComp;
