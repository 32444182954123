import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import merah from "../../imgAVIF/merah.avif";
import kuning from "../../imgAVIF/kuning.avif";
import hijau from "../../imgAVIF/Hijau.avif";

const DataHistoryFlagging = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [downloadFormat, setDownloadFormat] = useState("pdf");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleApplyFilter = () => {
    setOpen(true); // Show loading indicator

    // Simulate an API call
    setTimeout(() => {
      const data = [
        {
          no: 1,
          station: "TULT",
          lastActive: "2024-10-03 12:00:00",
          lastOff: "2024-10-03 12:00:00",
          flagNotif: "Merah",
          keterangan: "Data hilang lebih dari 15 kali",
          parameter: "CO2",
        },
        {
          no: 2,
          station: "GKU",
          lastActive: "2024-10-03 12:00:00",
          lastOff: "2024-10-03 12:00:00",
          flagNotif: "Kuning",
          keterangan: "Data konstan lebih dari 8 kali",
          parameter: "PM2.5",
        },
        {
          no: 3,
          station: "Deli",
          lastActive: "2024-10-03 12:00:00",
          lastOff: "2024-10-03 12:00:00",
          flagNotif: "Kuning",
          keterangan: "Data hilang lebih dari 8 kali",
          parameter: "PM2.5",
        },
        {
          no: 4,
          station: "TULT",
          lastActive: "2024-10-03 12:00:00",
          lastOff: "2024-10-03 12:00:00",
          flagNotif: "Kuning",
          keterangan: "Data konstan lebih dari 8 kali",
          parameter: "PM2.5",
        },
        {
          no: 5,
          station: "Deli",
          lastActive: "2024-10-03 12:00:00",
          lastOff: "2024-10-03 12:00:00",
          flagNotif: "Merah",
          keterangan: "Data konstan lebih dari 15 kali",
          parameter: "CO2",
        },
      ];

      setFilteredData(data);
      setOpen(false); // Hide loading indicator
    }, 1000);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableNode = document.getElementById("data-table");

    doc.autoTable({
      html: tableNode,
    });

    doc.save("data-history-flagging.pdf");
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data History Flagging");
    XLSX.writeFile(workbook, "data-history-flagging.xlsx");
  };

  return (
    <Container>
      <Box mb={3} textAlign="left">
        <Typography variant="h1" fontWeight="semi-bold">
          Data History Flagging
        </Typography>
        <Typography variant="subtitle1">Data History of Flagging System</Typography>
      </Box>

      <Box mb={2} display="flex" alignItems="center" gap="10px">
        <TextField
          label="Start Date"
          type="date"
          value={startDate.toISOString().split("T")[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }}
          sx={{ width: "30%" }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate.toISOString().split("T")[0]}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }}
          sx={{ width: "30%" }}
        />
        <FormControl sx={{ width: "20%" }}>
          <InputLabel id="download-format-label">Download Format</InputLabel>
          <Select
            labelId="download-format-label"
            value={downloadFormat}
            onChange={(e) => setDownloadFormat(e.target.value)}
          >
            <MenuItem value="pdf">PDF</MenuItem>
            <MenuItem value="excel">Excel</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box p="10px" bgcolor="#D6E6F2" color="#000" overflow="auto">
        <Typography variant="subtitle2" marginBottom="5px">
          Total entries: {filteredData.length}
        </Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: "auto" }}>
          <Table id="data-table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>No</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Station</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Last Active</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Last Off</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Flag Notif</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Keterangan</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Parameters</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.no}</TableCell>
                  <TableCell>{row.station}</TableCell>
                  <TableCell>{row.lastActive}</TableCell>
                  <TableCell>{row.lastOff}</TableCell>
                  <TableCell>
                    <img
                      src={row.flagNotif === "Merah" ? merah : row.flagNotif === "Kuning" ? kuning : hijau}
                      alt={row.flagNotif}
                      width="30"
                      height="30"
                    />
                  </TableCell>
                  <TableCell>{row.keterangan}</TableCell>
                  <TableCell>{row.parameter}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} display="flex" justifyContent="flex-start" gap="10px">
        <Button variant="contained" onClick={handleApplyFilter}>
          Apply Filter
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            downloadFormat === "pdf" ? downloadPDF() : downloadExcel()
          }
        >
          Download Data History {downloadFormat.toUpperCase()}
        </Button>
      </Box>

      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DataHistoryFlagging;
