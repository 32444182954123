import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";

// Fungsi untuk menghasilkan data dummy
const generateDummyData = (count, max, step) => {
  return Array.from({ length: count }, (_, i) => ({
    time: `${i < 10 ? "0" : ""}${i}:00`,
    visits: Math.floor(Math.random() * (max / step)) * step || 0,
  }));
};

// Data untuk setiap filter waktu
const oneHourData = [
  { time: "00:00", visits: 50 },
  { time: "00:05", visits: 100 },
  { time: "00:10", visits: 75 },
  { time: "00:15", visits: 120 },
  { time: "00:20", visits: 80 },
  { time: "00:25", visits: 60 },
  { time: "00:30", visits: 110 },
  { time: "00:35", visits: 130 },
  { time: "00:40", visits: 90 },
  { time: "00:45", visits: 70 },
  { time: "00:50", visits: 150 },
  { time: "00:55", visits: 95 },
];
const sixHourData = generateDummyData(6, 3000, 500);
const dailyData = generateDummyData(24, 10000, 1000);
const weeklyData = generateDummyData(7, 71000, 10000);

const LineChartVisit = () => {
  const [timeFilter, setTimeFilter] = useState("Last One Hour");
  const [data, setData] = useState(oneHourData);

  const handleTimeFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  useEffect(() => {
    switch (timeFilter) {
      case "Last One Hour":
        setData(oneHourData);
        break;
      case "Last 6 Hours":
        setData(sixHourData);
        break;
      case "Last 24 Hours":
        setData(dailyData);
        break;
      case "Last 7 Days":
        setData(weeklyData.map((item, index) => ({
          ...item,
          time: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][index]
        })));
        break;
      default:
        break;
    }
  }, [timeFilter]);

  return (
    <Box mb={4} p={2} style={{ backgroundColor: "#D6E6F2", borderRadius: "8px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h3" fontWeight="550" color="#000000">
          Visit Trend
        </Typography>
        <Select
          value={timeFilter}
          onChange={handleTimeFilterChange}
          style={{ minWidth: 150, maxHeight: "30px" }}
          sx={{
            "& .MuiSelect-selectMenu": { padding: "10px 14px", fontSize: "14px" },
            "& .MuiInputBase-root": { fontSize: "14px" },
          }}
        >
          <MenuItem value="Last One Hour">Last One Hour</MenuItem>
          <MenuItem value="Last 6 Hours">Last 6 Hours</MenuItem>
          <MenuItem value="Last 24 Hours">Last 24 Hours</MenuItem>
          <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
        </Select>
      </Box>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" label={{ value: timeFilter === "Last 7 Days" ? "Day" : "Time", position: 'insideBottom', offset: -5 }} />
          <YAxis label={{ value: "Visits", angle: -90, position: 'insideLeft' }} />
          <Tooltip formatter={(value) => `${value} visits`} />
          <Line type="monotone" dataKey="visits" stroke="#16425B" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default LineChartVisit;
