import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Menambahkan ikon default untuk marker
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const GPSMap = () => {
  const locations = [
    { name: "TULT", lat: -6.9680808, lng: 107.6262785 },
    { name: "GKU", lat: -6.973022, lng: 107.6297014 },
    { name: "DELI", lat: -6.975507, lng: 107.6296719 },
  ];

  return (
    <div>
      <h2>Air Monitoring Stations Map</h2>
      <MapContainer center={[-6.973022, 107.6297014]} zoom={13} style={{ height: "350px", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
      <div style={{ marginTop: "20px" }}>
        <p>Stasiun: TULT, GKU, DELI</p>
      </div>
    </div>
  );
};

export default GPSMap;
