import { Container, Grid, Card, CardMedia, CardContent } from "@mui/material";
import "./artikel.scss";
import thumbnail from "../../imgAVIF/Rectangle-4.avif";

function Artikel() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="Artikel">
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container justifyContent="center">
          {arr.map((i) => {
            return (
              <Grid item md={2.5} key={i} sx={{ m: 2 }} className="box">
                {/* <Link to="/"> */}
                  <Card sx={{ height: 250 }}>
                    <CardMedia
                      component="img"
                      height="140"
                      maxWidth="100%"
                      image={thumbnail}
                      alt="thumbnail"
                    />
                    <CardContent>
                      <h3>Title</h3>
                      <p>Lorem, ipsum dolor sit amet consectetur.</p>
                    </CardContent>
                  </Card>
                {/* </Link> */}
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

export default Artikel;
