import React, { useCallback, useState } from "react";
import SelectBox from "devextreme-react/select-box";
import {
  PolarChart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  ValueAxis,
  Margin,
  Export,
} from "devextreme-react/polar-chart";
import { windSources, windRoseData, periodLabel } from "./data.js";
import Container from "@mui/material/Container"; // Import Container from Material-UI

function onLegendClick({ target: series }) {
  if (series.isVisible()) {
    series.hide();
  } else {
    series.show();
  }
}

function App() {
  const [periodValues, setPeriodValues] = useState(windRoseData[0].values);
  const handleChange = useCallback(
    ({ value }) => {
      setPeriodValues(value);
    },
    [setPeriodValues]
  );

  return (
    <div style={{ backgroundColor: "#152b56" }}>
      <Container
        maxWidth="sm"
        sx={{ pt: 10, textAlign: "center", color: "white" }}
      >
        <h2 style={{ letterSpacing: "3px", fontSize: "32px" }}>Windrose</h2>
        <p
          style={{
            paddingTop: "2px",
            paddingBottom: "30px",
            paddingLeft: "10px",
            paddingRight: "10px",
            letterSpacing: "2px",
          }}
        >
          Explore the prevailing wind directions and intensities in your area!
        </p>
      </Container>
      <div
        id="chart-demo"
        style={{
          backgroundColor: "#ffffff",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <PolarChart
          id="radarChart"
          palette="Soft"
          dataSource={periodValues}
          onLegendClick={onLegendClick}
          style={{ width: "100%" }}
        >
          <CommonSeriesSettings type="stackedbar" />
          {windSources.map((item) => (
            <Series key={item.value} valueField={item.value} name={item.name} />
          ))}
          <Margin bottom={10} left={10} />
          <ArgumentAxis
            discreteAxisDivisionMode="crossLabels"
            firstPointOnStartAngle={true}
          />
          <ValueAxis valueMarginsEnabled={false} />
        </PolarChart>
        <div className="center">
          {/* <SelectBox
            width="300"
            dataSource={windRoseData}
            inputAttr={periodLabel}
            displayExpr="period"
            valueExpr="values"
            value={periodValues}
            onValueChanged={handleChange}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
