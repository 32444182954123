import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../ThemeDashboard";
// import { mockBarData as data } from "../data/DataDummy";

const BarChart = ({ availabilityData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let pm25 = [];
  let co2 = [];
  let T = [];
  let RH = [];
  let Tsh = [];
  let RHsh = [];
  let WS = [];
  let WD = [];

  if (availabilityData) {
    pm25 = availabilityData.map(item => parseFloat(item['%pm25']).toFixed(1));
    co2 = availabilityData.map(item => parseFloat(item['%co2']).toFixed(1));
    T = availabilityData.map(item => parseFloat(item['%temperature']).toFixed(1));
    RH = availabilityData.map(item => parseFloat(item['%humidity']).toFixed(1));
    Tsh = availabilityData.map(item => parseFloat(item['%sht31_temp']).toFixed(1));
    RHsh = availabilityData.map(item => parseFloat(item['%sht31_hum']).toFixed(1));
    WS = availabilityData.map(item => parseFloat(item['%ws']).toFixed(1));
    WD = availabilityData.map(item => parseFloat(item['%wd']).toFixed(1));
  } else {
    // Handle jika availabilityData null
    console.error("availabilityData is null or undefined.");
  }
  
  

  const myData = [
    {
      parameter: "PM2.5",
      "persenavail": pm25,
    
    },
    {
      parameter: "CO2",
      "persenavail": co2,
     
    },
    {
      parameter: "T",
      "persenavail": T,
  
    },
    {
      parameter: "RH",
      "persenavail": RH,
     
    },
    {
      parameter: "T Shelter",
      persenavail: Tsh, 
    },
    {
      parameter: "RH Shelter",
      persenavail: RHsh,
    },
    {
      parameter: "WS",
      "persenavail": WS,
     
    },
    {
      parameter: "WD",
      "persenavail": WD,
   
    },
  ]

  return (
    <ResponsiveBar
      data={myData}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={["persenavail"]}
      indexBy="parameter"
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear", min: 0, max: 100 }}
      indexScale={{ type: "band", round: true }}
      colors={(d) => {
        if (d.value > 75) {
          return "#16425B"; // Warna biru untuk nilai di atas 75%
        } else if (d.value > 50) {
          return "orange"; // Warna oranye untuk nilai antara 50% hingga 75%
        } else if (d.value > 25) {
          return "red"; // Warna merah untuk nilai antara 25% hingga 50%
        } else {
          return "black"; // Warna hitam untuk nilai di bawah 25%
        }
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend:  availabilityData  ? undefined : "parameter",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: availabilityData  ? undefined : "persenavail",
        legendPosition: "middle",
        legendOffset: -40,
        format: (value) => `${value}%`,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={colors.birulangit[500]}
      role="application"
      barAriaLabel={function (e) {
        return (
          e.id + ": " + e.formattedValue + " in parameter: " + e.indexValue
        );
      }}
    />
  );
};

export default BarChart;
