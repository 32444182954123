import axios from "axios";
import React, { useEffect, useState } from "react";
import "chartjs-adapter-date-fns";
// import "./VisualChart.scss";
import { Grid } from "@mui/material";
import { FaFaceFrownOpen, FaFaceLaughBeam } from "react-icons/fa6";
// import { FaFaceFrownOpen, FaFaceLaughBeam, FaFaceMeh } from "react-icons/fa6";

//image
import emoteHijau from "../../../imgAVIF/emote_hijau.avif";
// import emoteKuning from "../../../img/emote_kuning.png";
import emoteMerah from "../../../imgAVIF/emote_merah.avif";

//chartJS data
import {
  Chart as ChartJS,
  registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

// Chart Graph Code
export function ChartAcid({ res }) {
  //initialize data value
  const [selectedParameter, setSelectedParameter] = useState("pH");
  const [selectedParameterUnit, setSelectedParameterUnit] = useState(" ");
  // const [selectedTime, setSelectedTime] = useState("hourly");
  // const [setSelectedTime] = useState("hourly");
  const [standard, setstandard] = useState({
    // high: 0,
    // low: 100,
  });
  // const [standardTemp, setStandardTemp] = useState({
  //   high: 100,
  //   low: 50,
  // });

  // Option code (styling, etc)
  const commonOption = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    height: "350px",
    width: "720px",
  };

  // initialization chart storage data
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
      },
    ],
  });

  // initialization chart storage data
  const [buttonData, setButtonData] = useState({
    pH: [],
    Temp: [],
    EC: [],
    Rainfall: [],
    RainCond: [],
    AutoCond: [],
  });

  //fetching data
  useEffect(() => {
    // // Extract data from the response
    // if (res) {
    //   const labels = res.data
    //     .slice(400, 424)
    //     .map((dataObj) => dataObj.created_at);

    //   const data = res.data
    //     .slice(400, 424)
    //     .map((dataObj) => dataObj[selectedParameter]);

    const fetchData = async () => {
      // Fetch data based on the parameter state
      const url1 = `https://biru-langit.com/api/acidrains/data`;
      const res = await axios.get(url1);

      // Extract data from the response
      const labels = res.data.slice(-24).map((dataObj) => dataObj.Time);
      const data = res.data
        .slice(-24)
        .map((dataObj) => dataObj[selectedParameter]);
      // console.log("fetched data", res);

      // Update the data state
      setData({
        labels,
        datasets: [
          {
            label: `${selectedParameter.toUpperCase()} (${selectedParameterUnit})`,
            data,
            backgroundColor: "#163461",
          },
        ],
      });

      // Update the button data state
      setButtonData({
        pH: res.data.slice(-1).map((dataObj) => dataObj.pH),
        Temp: res.data.slice(-1).map((dataObj) => dataObj.Temp),
        EC: res.data.slice(-1).map((dataObj) => dataObj.EC),
        Rainfall: res.data.slice(-1).map((dataObj) => dataObj.Rainfall),
        RainCond: res.data.slice(-1).map((dataObj) => dataObj.RainCond),
        AutoCond: res.data.slice(-1).map((dataObj) => dataObj.AutoCond),
      });
    };
    fetchData();
  }, [selectedParameter, selectedParameterUnit, res]);
  // [selectedParameter]);

  //handle the data when the parameter choosed
  const handleDataChange = (newData, unit, high, low) => {
    setSelectedParameter(newData);
    setSelectedParameterUnit(unit);
    setstandard({ high, low });
    // setStandardTemp({ high, low });
  };

  //handle the data when the time category choosed
  // const handleButtonClick = (buttonName) => {
  //   setSelectedTime(buttonName);
  // };

  //mapping indicator
  const ind = [
    {
      class: "high",
      title: "Low",
      range: `< ${standard.low}`,
      icon: <FaFaceFrownOpen className='ind-icon' />,
    },
    // FaFaceMeh
    {
      class: "low",
      title: "Normal",
      range: `${standard.low} - ${standard.high}`,
      icon: <FaFaceLaughBeam className='ind-icon' />,
    },
    {
      class: "high",
      title: "High",
      range: `> ${standard.high} `,
      icon: <FaFaceFrownOpen className='ind-icon' />,
    },
  ];

  // //mapping indicator
  // const indTemp= [
  //   {
  //     class: "low",
  //     title: "Low",
  //     range: `< ${standardTemp.low}`,
  //     icon: <FaFaceLaughBeam className="ind-icon" />,
  //   },
  //   {
  //     class: "mid",
  //     title: "Medium",
  //     range: `${standardTemp.low} - ${standardTemp.high}`,
  //     icon: <FaFaceMeh className="ind-icon" />,
  //   },
  //   {
  //     class: "high",
  //     title: "High",
  //     range: `> ${standardTemp.high} `,
  //     icon: <FaFaceFrownOpen className="ind-icon" />,
  //   },
  // ];

  const getEmoteImage = (high, low, value) => {
    if (value > high) return emoteMerah;
    else if (value >= low) return emoteHijau;
    else if (value < low) return emoteMerah;
  };

  // //image conditional
  // const getEmoteImage = (param, value) => {
  //   if (param === 'pH') {
  //     if (value < 5.6) return emoteMerah;
  //     else if (value >= 5.6) return emoteHijau;
  //     // else if (value <= 6) return emoteHijau;
  //   } else if (param === 'Temp') {
  //     if (value > 26) return emoteMerah;
  //     else if (value >= 26) return emoteHijau;
  //     else if (value <= 24) return emoteMerah;
  //   } else if (param === 'EC') {
  //     if (value <= 0) return emoteMerah;
  //     else if (value > 0) return emoteHijau;
  //     // else if (value > 0) return emoteHijau;
  //     // else if (value <= 15) return emoteMerah;
  //   } else if (param === 'Rainfall') {
  //     if (value > 100) return emoteMerah;
  //     else if (value > 50) return emoteKuning;
  //     else if (value >= 0) return emoteHijau;

  //   } else if (param === 'RainCond') {
  //     if (value == 1) return emoteHijau;
  //     else if (value == 0) return emoteMerah;
  //   } else if (param === 'AutoCond') {
  //     if (value == 1) return emoteHijau;
  //     else if (value == 0) return emoteMerah;
  //   }
  // };

  function parameterButton(
    parameter,
    unit,
    buttonValue,
    buttonName,
    high,
    low
  ) {
    return (
      <button onClick={() => handleDataChange(parameter, unit, high, low)}>
        {/* <button onClick={() => handleDataChange(parameter, unit)}> */}
        <div className='button-container'>
          <div className='button-name'>{buttonName}</div>
          <div className='button-value'>{buttonValue}</div>
        </div>
        <div className='emote'>
          <img src={getEmoteImage(high, low, buttonValue)} alt='EmoteVal' />
        </div>
      </button>
    );
  }

  return (
    <>
      <div className='graph-container'>
        <div
          className='top-graph'
          // style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <div className="graph-time">
            <button
              className={selectedTime === "hourly" ? "selected" : ""}
              onClick={() => handleButtonClick("hourly")}
            >
              Hourly
            </button>
            <button
              className={selectedTime === "daily" ? "selected" : ""}
              onClick={() => handleButtonClick("daily")}
            >
              Daily
            </button>
            <button
              className={selectedTime === "weekly" ? "selected" : ""}
              onClick={() => handleButtonClick("weekly")}
            >
              Weekly
            </button>
            <button
              className={selectedTime === "monthly" ? "selected" : ""}
              onClick={() => handleButtonClick("monthly")}
            >
              Monthly
            </button>
          </div> */}
          <div className='station-name'>
            <p>Telkom University - GKU</p>
          </div>
        </div>
        <div className='bottom-graph'>
          <div className='graph-parameter'>
            <div className='scrollable-button'>
              {/* <button onClick={() => handleDataChange('pH', " ")}> 
              <div className='button-container'>
                <div className='button-name'>pH</div>
                <div className='button-value'>
                  {buttonData.pH}
                </div>
              </div>
              <div className='emote'>
                <img
                  src={getEmoteImage('pH', buttonData.pH)}
                  alt="EmoteVal" />
              </div>
            </button> */}
              {parameterButton("pH", " ", buttonData.pH, "pH", 7, 5.6)}

              {/* <button onClick={() => handleDataChange('Temp', "°C")}>
              <div className='button-container'>
                <div className='button-name'>Temp</div>
                <div className='button-value'>
                  {buttonData.Temp}
                </div>
              </div>
              <div className='emote'>
                <img
                  src={getEmoteImage('Temp', buttonData.Temp)}
                  alt="EmoteVal" />
              </div>
            </button>               */}
              {parameterButton("Temp", "°C", buttonData.Temp, "Temp", 26, 24)}

              {/* <button onClick={() => handleDataChange('EC', "ms/cm")}>
              <div className='button-container'>
                <div className='button-name'>EC</div>
                <div className='button-value'>
                  {buttonData.EC}
                </div>
              </div>
              <div className='emote'>
                <img
                  src={getEmoteImage('EC', buttonData.EC)}
                  alt="EmoteVal" />
              </div>
            </button>   */}
              {parameterButton("EC", "ms/cm", buttonData.EC, "EC", 100, 0)}

              {/* <button onClick={() => handleDataChange('Rainfall', "mm")}>
              <div className='button-container'>
                <div className='button-name'>Rainfall</div>
                <div className='button-value'>
                  {buttonData.Rainfall}
                </div>
              </div>
              <div className='emote'>
                <img
                  src={getEmoteImage('Rainfall', buttonData.Rainfall)}
                  alt="EmoteVal" />
              </div>
            </button>  */}
              {parameterButton(
                "Rainfall",
                "mm",
                buttonData.Rainfall,
                "Rainfall",
                100,
                0
              )}

              {/* <button onClick={() => handleDataChange('RainCond', "rain/norain")}>
              <div className='button-container'>
                <div className='button-name'>RainCond</div>
                <div className='button-value'>
                  {buttonData.RainCond}
                </div>
              </div>
              <div className='emote'>
                <img
                  src={getEmoteImage('RainCond', buttonData.RainCond)}
                  alt="EmoteVal" />
              </div>
            </button>  */}
              {parameterButton(
                "RainCond",
                "rain(1)/norain(0)",
                buttonData.RainCond,
                "RainCond",
                1,
                0
              )}

              {/* <button onClick={() => handleDataChange('AutoCond', "open/close")}>
              <div className='button-container'>
                <div className='button-name'>AutoCond</div>
                <div className='button-value'>
                  {buttonData.AutoCond}
                </div>
              </div>
              <div className='emote'>
                <img
                  src={getEmoteImage('AutoCond', buttonData.AutoCond)}
                  alt="EmoteVal" />
              </div>
            </button>  */}
              {parameterButton(
                "AutoCond",
                "open(1)/close(0)",
                buttonData.AutoCond,
                "AutoCond",
                1,
                0
              )}
            </div>
          </div>

          <div className='chart-wrapper'>
            {data.datasets[0].data ? (
              <div className='chart'>
                <Bar data={data} options={commonOption} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ letterSpacing: "1px" }}>Getting the data...</h3>
                <p style={{ letterSpacing: "1px", margin: "0" }}>
                  please wait for a moment!
                </p>
              </div>
            )}
          </div>
        </div>
        {standard.high !== "" && standard.low !== "" && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {ind.map((item, i) => (
              <Grid item xs={4} key={i}>
                <div className={`chip ${item.class}`}>
                  <p className='range'>{item.range}</p>
                  <p>
                    <b>{item.title}</b>
                  </p>
                  {item.icon}
                </div>
              </Grid>
            ))}
          </Grid>
        )}

        {/* {standardTemp.high !== "" && standardTemp.low !== "" && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {indTemp.map((item, i) => (
              <Grid item xs={4} key={i}>
                <div className={`chip ${item.class}`}>
                  <p className="range">{item.range}</p>
                  <p>
                    <b>{item.title}</b>
                  </p>
                  {item.icon}
                </div>
              </Grid>
            ))}
          </Grid>
        )}  */}

        {selectedParameter === "pH" && (
          <p style={{ paddingTop: "5px" }}>
            {/* *Indicator based on quality standard of 8 hours of O3 */}
          </p>
        )}
        {selectedParameter === "Temp" && (
          <p style={{ paddingTop: "5px" }}>
            {/* *Indicator based on quality standard of 24 hours of CH4 */}
          </p>
        )}
        {selectedParameter === "EC" && (
          <p style={{ paddingTop: "5px" }}>
            {/* *Indicator based on quality standard of 24 hours of CH4 */}
          </p>
        )}
        {selectedParameter === "Rainfall" && (
          <p style={{ paddingTop: "5px" }}>
            {/* *Indicator based on quality standard of 24 hours of CH4 */}
          </p>
        )}
        {selectedParameter === "RainCond" && (
          <p style={{ paddingTop: "5px" }}>
            {/* *Indicator based on quality standard of 24 hours of CH4 */}
          </p>
        )}
        {selectedParameter === "AutoCond" && (
          <p style={{ paddingTop: "5px" }}>
            {/* *Indicator based on quality standard of 24 hours of CH4 */}
          </p>
        )}
      </div>
    </>
  );
}
