import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../ThemeDashboard";

const ProgressCircle = ({ performanceData, size = "40" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let pf = [];

  // Mengecek apakah performanceData ada dan apakah itu array
  if (performanceData && Array.isArray(performanceData)) {
    // Mengambil nilai %performance dari setiap item dalam performanceData
    pf = performanceData.map((item) => item["%performance"]);
  }

  // Menghitung nilai rata-rata dari pf jika pf memiliki nilai
  const averagePm25 =
    pf.length > 0 ? pf.reduce((acc, val) => acc + val, 0) / pf.length : 0;

  // Menghitung percentage dengan 1 angka dibelakang koma
  const percentage = averagePm25.toFixed(1);

  // Menghitung angle berdasarkan percentage
  const angle = averagePm25 * 3.6;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%", // Ubah lebar menjadi 100% agar responsif
        // maxWidth: `${size}px`, // Tetapkan maksimum lebar
        height: "100%", // Atur tinggi menjadi otomatis agar menyesuaikan dengan lebar
        margin: "0 auto", // Atur margin untuk membuatnya berada di tengah
        paddingBottom: "100%", // Buat tinggi sama dengan lebar agar menjadi lingkaran
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${colors.birulangit[200]} 0deg 360deg),
            ${colors.birulangit[100]}`,
          borderRadius: "50%",
          width: "100%",
          height: "100%",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography variant="h1" color="text.primary" fontWeight="550">
          {performanceData !== null ? `${percentage}%` : "N/A"}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressCircle;
