import React from "react";
import { Container, Grid } from "@mui/material";
import MapComp from "./MapCompSingle";

const MapSingle = ({ res,data }) => {
  return (
<Container
        // maxWidth="lg"
        sx={{textAlign: "center", color: "white" }}
      >
        {res ? (
          <Grid container spacing={5} justifyContent="flex-start"> 
            <Grid item lg={6} md={12} sm={12} xs={12}>
            <MapComp
                  label={"AQI"}
                  high={data.map(user => user.high)}
                  low={data.map(user => user.low)}
                  selectedParams={data.map(user => user.parameter)}
                  unit={data.map(user => user.unit)}
                  res={res}
                />
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              minHeight: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                padding: "10px 80px",
                fontWeight: "400",
                letterSpacing: "2px",
              }}
            >
              Generating all data ...
            </h3>
            <p style={{ letterSpacing: "1px" }}>please wait for a moment!</p>
          </div>
        )}
      </Container>
  );
};

export default MapSingle;
