import React from "react";
import { Container, Grid } from "@mui/material";
import MapComp from "./MapComp";

const Map = ({ res,data }) => {
  return (
    <div style={{ backgroundColor: "#23477d" }}>
      <Container
        maxWidth="lg"
        sx={{ pb: 5, textAlign: "center", color: "white" }}
      >
        <h2 style={{ letterSpacing: "3px", fontSize: "32px" }}>Monitor Map</h2>
        <p
          style={{
            paddingTop: "2px",
            paddingBottom: "30px",
            paddingLeft: "10px",
            paddingRight: "10px",
            letterSpacing: "2px",
          }}
        >
          Check our map to easily found out the air and radon quality in your
          area!
        </p>
        {res ? (
          <Grid container spacing={5} justifyContent="center">
            {data.map((item, i) => (
              <Grid item lg={6} md={6} sm={10} xs={11} key={i}>
                <MapComp
                  title={`${item.name} Map`}
                  label={item.name}
                  high={item.high}
                  low={item.low}
                  selectedParams={item.parameter}
                  unit={item.unit}
                  res={res}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div
            style={{
              minHeight: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                padding: "10px 80px",
                fontWeight: "400",
                letterSpacing: "2px",
              }}
            >
              Generating all data ...
            </h3>
            <p style={{ letterSpacing: "1px" }}>please wait for a moment!</p>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Map;
