import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Button, Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../Components/Dashboard/ThemeDashboard";
import Header from "../../Components/Dashboard/ComponentDashboard/Header";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const LogMain = ({ logs, setLogs }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetch("https://biru-langit.com/api/logs");
        if (!response.ok) {
          throw new Error("Failed to fetch logs data");
        }
        const data = await response.json();
        setLogs(data);
      } catch (error) {
        console.error("Error fetching logs data:", error);
      }
    };

    fetchLogs();
  }, [setLogs]);

  const updateLog = async (id, updatedLog) => {
    try {
      const response = await fetch(`https://biru-langit.com/api/logs/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedLog),
      });

      if (!response.ok) {
        throw new Error("Failed to update log");
      }

      const data = await response.json();
      setLogs((prevLogs) => prevLogs.map((log) => (log.id === id ? data : log)));
    } catch (error) {
      console.error("Error updating log:", error);
    }
  };

  const deleteLog = async (id) => {
    try {
      const response = await fetch(`https://biru-langit.com/api/logs/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete log");
      }

      return response.json();
    } catch (error) {
      console.error("Error deleting log:", error);
    }
  };

  const handleDeleteLog = (id) => {
    deleteLog(id)
      .then(() => {
        setLogs((prevLogs) => prevLogs.filter((log) => log.id !== id));
      })
      .catch((error) => {
        console.error("Failed to delete log:", error);
      });
  };

  const handleEditLog = (id) => {
    const logToEdit = logs.find((log) => log.id === id);
    navigate("/addlog", { state: { logToEdit, isEdit: true } });
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Log Maintenance Report", 20, 10);

    const headers = [
      ["No", "Date", "Station", "Report", "Start Repair", "Finished Repair", "Status"],
    ];
    const data = logs.map((log, index) => [
      index + 1,
      new Date(log.date).toISOString().split("T")[0],
      log.station,
      log.report,
      log.startRepair,
      log.finishedRepair,
      log.status,
    ]);

    doc.autoTable({
      head: headers,
      body: data,
    });

    doc.save("log_maintenance_report.pdf");
  };

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      logs.map((log, index) => ({
        No: index + 1,
        Date: new Date(log.date).toISOString().split("T")[0],
        Station: log.station,
        Report: log.report,
        "Start Repair": log.startRepair,
        "Finish Repair": log.finishedRepair,
        Status: log.status,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Logs");
    XLSX.writeFile(wb, "log_maintenance_report.xlsx");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (format) => {
    handleClose();
    if (format === "pdf") {
      handleDownloadPDF();
    } else if (format === "excel") {
      handleDownloadExcel();
    }
  };

  const columns = [
    { field: "no", headerName: "No" },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "station",
      headerName: "Station",
      headerAlign: "center",
      align: "center",
      cellClassName: "station-column--cell",
    },
    {
      field: "report",
      headerName: "Report",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "start",
      headerName: "Start Repair",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "finish",
      headerName: "Finish Repair",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        const { status } = row;
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="4px"
          >
            {status === "done" && <CheckCircleOutlineIcon />}
            {status === "pending" && <PauseCircleOutlineIcon />}
            {status === "on progress" && <AccessTimeIcon />}
            <Typography sx={{ ml: "5px" }}>{status}</Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box width="100%" display="flex" justifyContent="space-around">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#D6E6F2", color: "black" }}
              onClick={() => handleDeleteLog(row.id)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#D6E6F2", color: "black" }}
              onClick={() => handleEditLog(row.id)}
            >
              Edit
            </Button>
          </Box>
        );
      },
    },
  ];

  const rows = logs.map((log, index) => ({
    id: log.id,
    no: index + 1,
    date: new Date(log.date).toISOString().split("T")[0],
    station: log.station,
    report: log.report,
    start: log.startRepair,
    finish: log.finishedRepair,
    status: log.status,
  }));

  const handleAddLog = () => {
    navigate("/addlog");
  };

  return (
    <Box m="20px">
      <Header title="Log Maintenance" />
      <Box display="flex" alignItems="center" justifyContent="space-between" m="20px 0">
        <Typography variant="h4" component="h2">
          Log Maintenance
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
              border: "1px solid #000000",
            }}
            onClick={handleAddLog}
          >
            Add Log
          </Button>
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
              border: "1px solid #000000",
            }}
            onClick={handleClick}
          >
            Download
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => handleMenuClick("pdf")}>Download PDF</MenuItem>
            <MenuItem onClick={() => handleMenuClick("excel")}>Download Excel</MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            backgroundColor: "#ffffff",
            color: "#000000",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#D6E6F2",
            color: "#000000",
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle": {
            color: "#000000",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={rows} columns={columns} />
      </Box>
    </Box>
  );
};

export default LogMain;
