const dataAQMSTamansari = () => {
  return [
    {
      parameter: "co2",
      unit: "ppm",
      value: "buttonData.co2",
      name: "CO2",
      high: 1000,
      low: 800,
    },
    {
      parameter: "pm25",
      unit: "μg/m3",
      value: "buttonData.pm25",
      name: "PM2.5",
      high: 50,
      low: 10,
    },
    {
      parameter: "temperature",
      unit: "°C",
      value: "buttonData.temperature",
      name: "Temperature",
      high: 1000,
      low: 800,
    },
    {
      parameter: "humidity",
      unit: "%",
      value: "buttonData.humidity",
      name: "Humidity",
      high: 1000,
      low: 800,
    },
    {
      parameter: "sht31_temp",
      unit: "°C",
      value: "buttonData.temp",
      name: "Temperature Shelter",
      high: 35,
      low: 25,
    },
    {
      parameter: "sht31_hum",
      unit: "%",
      value: "buttonData.humid",
      name: "Humidity Shelter",
      high: 100,
      low: 80,
    },
  ];
};

export { dataAQMSTamansari };

const dataHeatmapTamansari = () => {
  return [
    {
      parameter: "co2",
      unit: "ppm",
      value: "co2",
      name: "CO2",
      high: 1000,
      low: 800,
    },
    {
      parameter: "pm25",
      unit: "μg/m3",
      value: "pm25",
      name: "PM2.5",
      high: 50,
      low: 10,
    },
    {
      parameter: "temperature",
      unit: "°C",
      value: "temperature",
      name: "Temperature",
      high: 1000,
      low: 800,
    },
    {
      parameter: "humidity",
      unit: "%",
      value: "humidity",
      name: "Humidity",
      high: 1000,
      low: 800,
    },
    {
      parameter: "sht31_temp",
      unit: "°C",
      value: "temp",
      name: "Temperature Shelter",
      high: 35,
      low: 25,
    },
    {
      parameter: "sht31_humid",
      unit: "%",
      value: "humid",
      name: "Humidity Shelter",
      high: 100,
      low: 80,
    },
  ];
};

export { dataHeatmapTamansari };
