import React, { useState,useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import axios from "axios"; // Import Axios
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import thumbnail from "../../imgAVIF/Rectangle-4.avif";

export default function Login({ setIsLoggedIn, setAccessToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there is an access token in localStorage
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Verify token validity (optional but recommended)
      // Example: You can decode JWT to verify expiration, etc.
      // If valid, setLoggedIn(true) and navigate to dashboard
      setIsLoggedIn(true);
      navigate("/dashboard");
    }
  }, []); // Only run once on component mount
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://biru-langit.com/api/dashboard/login",
        {
          username: username,
          password: password,
        }
      );

      const data = response.data; // Axios automatically parses JSON response

      if (response.status === 200) {
        setIsLoggedIn(true);
        setAccessToken(data.token);
        localStorage.setItem("accessToken", data.token);
        navigate("/dashboard");
      } else {
        setError(data.message || "Login failed");
      }
    } catch (err) {
      setError("Login failed. Please try again.");
    }
  };

  return (
    <div className="bg">
      <Container sx={{ pt: 20, pb: 10 }} maxWidth="md">
        <div className="box-auth">
          <Grid container justifyContent="center">
            <Grid
              className="plane-img"
              item
              md={6}
              sm={12}
              xs={12}
              sx={{ backgroundImage: `url(${thumbnail})` }}
            />
            <Grid item md={6} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 5,
                }}
              >
                <Typography component="h1" variant="h4">
                  Welcome Back!
                </Typography>
                <Typography component="h5">
                  Are you ready to learn something new?
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <Box component="form" sx={{ mt: 3 }} onSubmit={handleLogin}>
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    label="Username"
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoFocus
                  />
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                  {/* <Link href="/forgot-pass" variant="body2">
                    {"Forgot Password"}
                  </Link> */}
                  <Button
                    disabled={!username || !password}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Login
                  </Button>
                  {/* <Divider>or continue with</Divider>
                  <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    <BsGoogle style={{ marginRight: "10px" }} />
                    Google
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href="/register" variant="body2">
                        {"Don't have an account? Register"}
                      </Link>
                    </Grid>
                  </Grid> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
