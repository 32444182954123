import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import img from "../../imgAVIF/Rectangle-4.avif";

export default function WhatsappWarn() {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Data = {
      whatsapp: whatsappNumber,
    };
    setOpen(true);

    try {
      const response = await fetch("https://biru-langit.com/api/subscribe-whatsapp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Data),
      });

      if (!response.ok) {
        throw new Error("Failed to subscribe");
      }

      console.log("Subscription successful");
      window.location.href = "/thank-you";
    } catch (error) {
      console.error("Error subscribing WhatsApp:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg" style={{ background: "none" }}>
      <Container sx={{ pt: 18, pb: 10 }} maxWidth="sm">
        <div className="box">
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid
              item
              md={12}
              className="early-warn"
              style={{
                backgroundImage: `url(${img})`,
              }}
            />
            <Grid item md={12} sm={12} xs={12} sx={{ p: 3 }}>
              <Typography component="h1" variant="h4">
                Get an Early Warning?
              </Typography>
              <Typography component="h5">
                Let's go and get an early warning!
              </Typography>

              <form onSubmit={handleSubmit}>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  label="WhatsApp Number"
                  name="whatsapp"
                  autoComplete="tel"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                  autoFocus
                />
                <p>*Make sure your number is correct!</p>
                <Button
                  type="submit"
                  disabled={!whatsappNumber}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Thank you for subscribing!</DialogTitle>
        <DialogContent>
          Please check your WhatsApp for confirmation. If you do not receive a message, check your app settings.
        </DialogContent>
      </Dialog>
    </div>
  );
}
