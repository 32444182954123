import React, { useState, useEffect } from "react";
import { Container, Grid, Pagination } from "@mui/material";
import PublicationItem from "./PublicationItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

function PublicationList({ publication }) {
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredPublication, setFilteredPublication] = useState([]);

  // When the search text changes, update the filteredPublication
  useEffect(() => {
    setFilteredPublication(publication);
    setCurrentPage(1); // Reset to the first page when searching
  }, [publication]);

  const totalPages = Math.ceil(filteredPublication.length / itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(
    startIndex + itemsPerPage,
    filteredPublication.length
  );

  const publicationsForPage = filteredPublication.slice(startIndex, endIndex);
  const theme = createTheme({
    palette: {
      text:{
        primary:"#FFFFFF"
      },
      primary: {
        light: blue[50],
        main: blue[100],
        dark: blue[300],
        darker: blue[500],
      },
    },
  });
  return (
    <>
        <div className='Publikasi'>
          <Container maxWidth='lg' sx={{ py: 10 }}>
            <Grid container justifyContent='center'>
              {publicationsForPage.length === 0 ? (
                <div
                  style={{ color: "white" }}
                  className='no-publication-message'
                >
                  No publication found
                </div>
              ) : (
                publicationsForPage.map((publication) => (
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    md={5}
                    sx={{ m: 2 }}
                    className='box'
                    key={publication.id}
                  >
                    <PublicationItem {...publication} />
                  </Grid>
                ))
              )}
            </Grid>
      <ThemeProvider theme={theme}>
            {publication.length > itemsPerPage ||
            publicationsForPage.length === 1 ? (
              <div className='pagination'>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color='primary'
                  style={{ justifyContent: "Center" }}
                />
              </div>
            ) : null}
      </ThemeProvider>
          </Container>
        </div>
    </>
  );
}

export default PublicationList;
