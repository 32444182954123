import React, { useEffect, useState, useCallback } from "react";
import { Container, Grid, Card, CardMedia, CardContent } from "@mui/material";
import stationIMG from "../../imgAVIF/Rectangle-4.avif";
import "./OurStation.scss";
import { Link } from "react-router-dom";
import { StationList } from "../../data/StationList";
import Map from "../../Components/Map/Map";
import axios from "axios";
import { dataAQMSRadon } from "../Station/Radon/DataAQMSRadon";

function OurStation() {
  const station = StationList();

  return (
    <div className='our-station bg'>
      <Container sx={{ pt: 15, pb: 10 }} maxWidth='md'>
        <h1>Our Stations</h1>
        <p
          style={{
            paddingBottom: "30px",
            letterSpacing: "2px",
            textAlign: "center",
          }}
        >
          Check out our stations across Bandung!
        </p>
        <Grid container justifyContent='center'>
          {station.map((item, i) => {
            return (
              <Grid item md={3} key={i} sx={{ m: 2 }} className='box'>
                <Link to={item.url}>
                  <Card sx={{ height: 250, width: 250 }}>
                    <CardMedia component='img' height='140' width="100%" image={stationIMG} />
                    <CardContent>
                      <h3>{item.name}</h3>
                      <p>{item.parameter}</p>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

export default OurStation;
