import React from "react";
import {
  Container,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";

const DecisionSupportSystemTableTULT = ({ rows }) => {
  return (
    <div style={{ backgroundColor: "#152b56" }}>
      <Container
        maxWidth="sm"
        sx={{ pt: 10, textAlign: "center", color: "white" }}
      >
        <h2 style={{ letterSpacing: "5px", fontSize: "38px" }}>DECISION SUPPORT SYSTEM (TULT)</h2>
        <p
          style={{
            paddingTop: "2px",
            paddingBottom: "30px",
            paddingLeft: "5px",
            paddingRight: "5px",
            letterSpacing: "1px",
          }}
        >
          Get the newest decision support system of rainwater quality around you!
        </p>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} size="large">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Average pH
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Average Temp
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Average EC
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Cumulative Rainfall
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  Decision
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.Time_Rain}</TableCell>
                  <TableCell align="center"><div>{row.Avg_pH_TULT} </div></TableCell>
                  <TableCell align="center"><div>{row.Avg_temp_TULT} °C</div></TableCell>
                  <TableCell align="center"><div>{row.Avg_EC_TULT} ms/cm</div></TableCell>
                  <TableCell align="center"><div>{row.cum_CH_TULT} mm</div></TableCell>
                  <TableCell align="center"><div>{row.DSS_TULT} </div></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default DecisionSupportSystemTableTULT;
