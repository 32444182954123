import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import { ChartAcid } from "../../../Components/Chart/Acid/ChartAcid";
import DecisionSupportSystemTable from "../../../Components/DecisionSupportSystemTable/DecisionSupportSystemTable";

const StationAcid = () => {
  const [res, setRes] = useState(null);
  const [rows, setRows] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data based on the parameter state

      //TO DO : change url 1 to https://biru-langit.com/api/acidrains/data
      const url = `https://biru-langit.com/api/radons/data`;
      const url2 = `https://biru-langit.com/api/dssgkus/data`;
      const response = await axios.get(url);
      const response2 = await axios.get(url2);
      // console.log("ini res 1", response);
      // console.log("ini res 2", response2.data);
      setRes(response);
      setRows(response2.data);
    };
    fetchData();
  }, []);
  return (
    <div>
      <section className="bg">
        <Container sx={{ pt: 12, pb: 5 }} maxWidth="md">
          {/* TO DO : ngubah parameter yang ada di ChartAcid sesuai sama apa yang diminta*/}
          <ChartAcid res={res} />
        </Container>
      </section>
      {rows && <DecisionSupportSystemTable rows={rows} />}
    </div>
  );
};

export default StationAcid;
