  import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import { ChartAcidTULT } from "../../../Components/Chart/Acid/ChartAcidTULT";
import DecisionSupportSystemTableTULT from "../../../Components/DecisionSupportSystemTable/DecisionSupportSystemTableTULT";

const StationAcidTULT = () => {
  const [res, setRes] = useState(null);
  const [rows, setRows] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data based on the parameter state
      const url = `https://biru-langit.com/api/radons/data`;
      const url2 = `https://biru-langit.com/api/dsstults/data`;

      const response = await axios.get(url);
      // const response1 = await axios.get(url1);
      const response2 = await axios.get(url2);
      
      // console.log("ini res 1", response);
      // console.log("ini res 2", response1.data);
      // console.log("ini res 3", response2.data);
      setRes(response);
      // setRows(response1.data);
      setRows(response2.data);
    };
    fetchData();
  }, []);
  return (
    <div>
      <section className="bg">
        <Container sx={{ pt: 12, pb: 5 }} maxWidth="md">
          {/* TO DO : ngubah parameter yang ada di ChartAcid sesuai sama apa yang diminta*/}
          <ChartAcidTULT res={res} />
        </Container>
      </section>
      {rows && <DecisionSupportSystemTableTULT rows={rows} />}
    </div>
  );
};

export default StationAcidTULT;
