import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import data from "../../data/Jabar_By_Kec.json";

const MapCompSingle = ({
  title,
  label,
  high,
  low,
  selectedParams,
  // unit,
  res,
}) => {
  const feature = data.features.map((feature) => {
    return feature;
  });
  const [dataParams, setDataParams] = useState(null);
  const colors = ["red", "green", "orange"];

  useEffect(() => {
    const time = res.data.slice(-1).map((dataObj) => dataObj.created_at);
    const value = res.data.slice(-1).map((dataObj) => dataObj.AQI)
    // console.log("ini mapcompsingle", value)
    setDataParams({
      value,
      time,
    });
  }, [selectedParams, res]);

  const highlightFeature = (e) => {
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 1,
    });
    layer.openPopup();
  };

  const resetHighlight = (e) => {
    const layer = e.target;
    layer.setStyle({
      fillOpacity: 0.4,
    });
    layer.closePopup();
  };

  const onEachFeature = (feature, layer) => {
    const { KECAMATAN, KABKOT } = feature.properties;

    if (selectedParams) {
      if (KECAMATAN === "DAYEUHKOLOT") {
        const value = dataParams?.value;
        let message;

        let customPopup;

        if (message) {
          customPopup = `<b>${KECAMATAN}, ${KABKOT}</b><br/>${label} : ${value} `;
        } else {
          customPopup = `<b>${KECAMATAN}, ${KABKOT}</b><br/>${label} : ${value} `;
        }

        layer.bindPopup(customPopup);
        let colorIndex = 0;

        if (value > high) colorIndex = 0;
        else if (value > low) colorIndex = 2;
        else colorIndex = 1;

        // const colorIndex = Math.floor(Math.random() * colors.length);
        layer.options.fillColor = colors[colorIndex];
      } else {
        let customPopup = `<b>${KECAMATAN}, ${KABKOT}</b><br/>No Data`;

        layer.bindPopup(customPopup);
        layer.options.fillColor = "gray";
      }
    }
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });
  };

  const style = () => {
    return {
      weight: 2,
      color: "white",
      fillOpacity: 0.4,
    };
  };

  const mapStyle = {
    height: "44vh",
    width: "40vw",
    borderRadius: "20px",
  };

  return dataParams ? (
    <div>
      <h3
        style={{
          paddingBottom: "10px",
          letterSpacing: "2px",
          fontSize: "24px",
          paddingRight:"10px"
        }}
      >
        {title}
      </h3>
      <MapContainer
        zoom={10.5}
        scrollWheelZoom={true}
        style={mapStyle}
        center={[-6.972922, 107.631439]}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {feature && (
          <GeoJSON data={feature} style={style} onEachFeature={onEachFeature} />
        )}
        ;
      </MapContainer>
    </div>
  ) : (
    <div
      style={{
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3
        style={{
          padding: "10px 80px",
          fontWeight: "400",
          letterSpacing: "2px",
        }}
      >
        Generating data for {title} ...
      </h3>
      <p style={{ letterSpacing: "1px" }}>please wait for a moment!</p>
    </div>
  );
};

export default MapCompSingle;
