import React, { useState } from "react";
import logo from "../../imgAVIF/publication.avif";
import "./Publication.scss";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { Grid, Container } from "@mui/material";
import PublicationList from "./PublicationComponent/PublicationList";
import { publicationData } from "../../data/publicationData";
// import { useState } from "react";

function Publication() {
  const publication = publicationData();
  const [publications, setPublications] = useState(publication);
  const filterPublications = (query) => {
    const filteredPublications = publication.filter((publication) =>
      publication.title.toLowerCase().includes(query.toLowerCase())
    );
    setPublications(filteredPublications);
  };
  return (
    <>
      <section className='bg'>
        <Container maxWidth='md' sx={{ pt: 15, pb: 5 }}>
          <Grid container alignItems='center'>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt='publication'
                style={{
                  maxHeight: "300px",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} className='top-title'>
              <h1>Publication</h1>
              <p>Discover our publications</p>
              <SearchBar onSearch={filterPublications} />
            </Grid>
          </Grid>
        </Container>
      </section>
      <PublicationList publication={publications} />
    </>
  );
}

export default Publication;
