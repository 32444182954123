import React, { useEffect, useState, useMemo, useCallback } from "react";
import "chartjs-adapter-date-fns";
// import ChartButton from "../../../Components/Chart/ChartComponent/ChartButton";
import ParameterButton from "../../../Components/Chart/ChartComponent/ParameterButton";
import ChartComponentRadon from "../../../Components/Chart/ChartComponent/chartComponentRadon";
import IndicatorGrid from "../../../Components/Chart/ChartComponent/IndicatorGrid";
import { dataAQMSRadon } from "./DataAQMSRadon";

function ChartAQMSRadon({ res }) {
  const dataAQMS = useMemo(() => dataAQMSRadon(), []);
  // console.log(res);
  const initialButtonData = useMemo(() => {
    const buttonData = {};
    dataAQMS.forEach((item) => {
      buttonData[item.parameter] = [];
    });
    return buttonData;
  }, [dataAQMS]);
  //initialize data value
  const [selectedParameter, setSelectedParameter] = useState("null");
  const [selectedParameterUnit, setSelectedParameterUnit] = useState("null");
  const [selectedParameterName, setSelectedParameterName] = useState("null");
  // const [selectedTime, setSelectedTime] = useState("null");
  const [standard, setStandard] = useState({
    high: 1000,
    low: 800,
  });
  const [buttonData, setButtonData] = useState(initialButtonData);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: null,
      },
    ],
  });
  const [isDataAvailable, setIsDataAvailable] = useState(
    !!res && !!res.data && res.data.length > 0
  );
  // const handleButtonClick = (buttonName) => {
  //   setSelectedTime(buttonName);
  // };
  const handleDataChange = useCallback((key) => {
    const temp = dataAQMS[key];
    const newDataAvailable = !!res && !!res.data && res.data.length > 0;
    setSelectedParameter(temp.parameter);
    setSelectedParameterUnit(temp.unit);
    setStandard({ high: temp.high, low: temp.low });
    setSelectedParameterName(temp.name);
    setIsDataAvailable(newDataAvailable);
  }, [dataAQMS, res]);
  useEffect(() => {
    const firstItem = dataAQMS[0]; // Get the first item in dataAQMS
    if (firstItem) {
      setSelectedParameter(firstItem.parameter);
      setSelectedParameterUnit(firstItem.unit);
      setStandard({ high: firstItem.high, low: firstItem.low });
      setSelectedParameterName(firstItem.name);
    }
  }, [dataAQMS]);

  useEffect(() => {
    const getData = () => {
      if (!res) {
        return;
      }

      // Filter out data with a value of 0 
      // !!!ONLY APPLY FOR VALIDATED DATA!!!
      const filteredData = res.data.slice(-24).filter((dataObj) => {
        return parseFloat(dataObj[selectedParameter]) !== 0;
      });

      const labels = filteredData.map((dataObj) => dataObj.created_at);
      const data = filteredData.map((dataObj) => {
        return parseFloat(dataObj[selectedParameter]).toFixed(1);
      });
      const datasets = [
        {
          label: `${selectedParameter.toUpperCase()} (${selectedParameterUnit})`,
          data,
          backgroundColor: "rgb(22, 52, 97)",
        },
      ];
      // Update the data state
      setData({
        labels,
        datasets,
      });
      // Update the button data state
      const updatedButtonData = {};
      dataAQMS.forEach((item) => {
        updatedButtonData[item.parameter] = filteredData
          .slice(-1)
          .map((dataObj) => dataObj[item.parameter]);
      });
      setButtonData(updatedButtonData);
      setIsDataAvailable(true);
    };
    if (res || isDataAvailable) {
      getData();
    } else {
      const updateInterval = 1000;
      const timer = setInterval(() => {
        if (isDataAvailable) {
          getData();
          clearInterval(timer);
        }
      }, updateInterval);

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    selectedParameter,
    selectedParameterUnit,
    res,
    isDataAvailable,
    dataAQMS,
  ]);

  return (
    <div className='graph-container'>
      <div className='top-graph'>
        <div className='graph-time'>
          {/* <ChartButton
            onClick={() => handleButtonClick("hourly")}
            selected={selectedTime === "hourly"}
            label='Hourly'
          />
          <ChartButton
            onClick={() => handleButtonClick("daily")}
            selected={selectedTime === "daily"}
            label='Daily'
          />
          <ChartButton
            onClick={() => handleButtonClick("weekly")}
            selected={selectedTime === "weekly"}
            label='Weekly'
          />
          <ChartButton
            onClick={() => handleButtonClick("monthly")}
            selected={selectedTime === "monthly"}
            label='Monthly'
          /> */}
          {/* Other time buttons go here */}
        </div>
        <div className='station-name'>
          <p>{"AQMS Indoor"}</p>
        </div>
      </div>
      <div className='bottom-graph'>
        <div className='graph-parameter'>
          <div className='scrollable-button'>
            {isDataAvailable &&
              dataAQMS.map((item, i) => (
                <ParameterButton
                  key={i}
                  onClick={() => handleDataChange(i)}
                  parameter={item.parameter}
                  unit={item.unit}
                  value={buttonData[item.parameter]}
                  name={item.name}
                  high={item.high}
                  low={item.low}
                />
              ))}
          </div>
        </div>
        <ChartComponentRadon data={data} />
      </div>
      <IndicatorGrid standard={standard} name={selectedParameterName} />
    </div>
  );
}

export default ChartAQMSRadon;
