import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import data from "../../data/forecastingData.json";
import "./forecastTable.scss";

const ForecastTable = () => {
  const [res, setRes] = useState(null);
  const fetchData = useCallback(async () => {
    try {
      const url = `https://biru-langit.com/api/radons/valids/data`;
      const response = await axios.get(url);
      setRes(response);
      // console.log(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);
  // const range = {
  //   co2: {
  //     high: 1000,
  //     low: 800,
  //   },
  //   pm25: {
  //     high: 50,
  //     low: 10,
  //   },
  // };
  const rows = data;
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div style={{ backgroundColor: "#152b56" }}>
      <Container
        maxWidth="sm"
        sx={{ pt: 10, textAlign: "center", color: "white" }}
      >
        <h2 style={{ letterSpacing: "3px", fontSize: "32px" }}>Forecasting</h2>
        <p
          style={{
            paddingTop: "2px",
            paddingBottom: "30px",
            paddingLeft: "10px",
            paddingRight: "10px",
            letterSpacing: "2px",
          }}
        >
          Get the newest predicted data of air quality around you!
        </p>
        <TableContainer component={Paper} sx={{ overflow: 'auto', height: 440 }}>
          <Table sx={{ minWidth: 600 }} size="large">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ fontSize: "16px", fontWeight: "700" }}
                >
                  Date and Time
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "16px", fontWeight: "700" }}
                >
                  CO2
                  {/* &#40;ppb&#41; */}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "16px", fontWeight: "700" }}
                >
                  PM2.5
                  {/* &#40;ppm&#41; */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.time}</TableCell>
                  <TableCell align="center">
                    <div
                      className="table-chip"
                    >
                      {row.co2} ppm
                     
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div
                      className="table-chip"
                    >
                      {row.pm25} µm/m3
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default ForecastTable;
