import React from "react";
import { Card, CardMedia, CardContent } from "@mui/material";
import thumbnail from "../../../imgAVIF/Rectangle-4.avif";

export function PublicationItem({ title, url, abstract }) {
  const handleCardClick = () => {
    window.open(url, "_blank");
  };
  const truncateAbstract = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " ...";
    }
    return text;
  };
  const truncateTitle = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " ...";
    }
    return text;
  };

  return (
    <div className="publication-item">
      <Card
        sx={{ height: 350 }}
        onClick={handleCardClick}
        style={{ cursor: "pointer" }}
      >
        <CardMedia
          component="img"
          height="140"
          image={thumbnail}
          alt="thumbnail"
        />
        <CardContent>
          <div style={{ lineHeight: "1.5", margin: "0" }}>
            <h3 style={{ margin: "0" }}>{truncateTitle(title,90)}</h3>
            <p style={{ margin: "0" }}>{truncateAbstract(abstract, 200)}</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default PublicationItem;
