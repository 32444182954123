const DataTower = () => {
  return [
    {
      parameter:"pm25",
      unit: "μg/m3",
      value: "buttonData.pm25",
      name: "PM2.5",
      high: 50,
      low: 10,
    },
    {
      parameter: "co2",
      unit: "ppm",
      value: "buttonData.co2",
      name: "CO2",
      high: 1500,
      low: 400,
    },
    {
      parameter: "p",
      unit: "Pa",
      value: "buttonData.press",
      name: "Pressure",
      high: 2.5,
      low: 0.5,
    },
    {
      parameter: "t",
      unit: "°C",
      value: "buttonData.temp",
      name: "Temperature",
      high: 70,
      low: 60,
    },
    {
      parameter: "rh",
      unit: "%",
      value: "buttonData.humid",
      name: "Humidity",
      high: 100,
      low: 80,
    },
    {
        parameter: "ws",
        unit: "m/s",
        value: "buttonData.ws",
        name: "Wind Speed",
        high: 100,
        low: 80,
    },
    {
        parameter: "wd",
        unit: "°",
        value: "buttonData.wd",
        name: "Wind Direction",
        high: 360,
        low: 0,
    }, 
    {
        parameter: "mpu_x",
        unit: "°",
        value: "buttonData.pitch",
        name: "Pitch",
        high: 360,
        low: 0,
    }, 
    {
        parameter: "mpu_y",
        unit: "°",
        value: "buttonData.roll",
        name: "Roll",
        high: 360,
        low: 0,
    }, 
  ];
};

export { DataTower };
