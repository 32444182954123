import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, useMediaQuery, useTheme } from "@mui/material";
import "./Header.scss";
import logo from "../../imgAVIF/Logo.avif";
import DrawerComp from "./DrawerComp";

function Header() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="header">
      <Container maxWidth="lg">
        <nav>
          <Link to="/">
            <img className="logo" src={logo} alt="Biru Langit" width="200px" height="auto"/>
            <p className="span-logo">by INSIGHT Laboratorium</p>
          </Link>

          {isMatch ? (
            <DrawerComp />
          ) : (
            <>
              <div>
                {/* <Link to="/info-kebencanaan" className="nav-i">
                  Articles
                </Link> */}
                <Link to="/publication" className="nav-i">
                  Publication
                </Link>
                <Link to="/about-air-and-radon" className="nav-i">
                  About Air and Radon Quality
                </Link>
                <Link to="/our-station" className="nav-i">
                  Our Stations
                </Link>
                <Link to="/login" className="nav-i">
                  Performance Dashboard
                </Link>
              </div>
              <div>
                <Link to="/get-early-warning">
                  <Button sx={{ width: "200px" }} variant="contained">
                    Get an Early Warning
                  </Button>
                </Link>
              </div>
            </>
          )}
          {/* <DrawerComp /> */}
        </nav>
      </Container>
    </div>
  );
}

export default Header;
