const publicationData = () => {
  return [
    {
      id: 1,
      title: "Analysis of Indoor Air Quality Based on Low-Cost Sensors",
      url: "https://www.researchgate.net/publication/348116637_Analysis_of_Indoor_Air_Quality_Based_on_Low-Cost_Sensors?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6ImluZGV4IiwicGFnZSI6InByb2ZpbGUifX0",
      abstract:
        "According to WHO, indoor and/or outdoor air pollution is one of the main contributors to over two million premature deaths each year. As most of the human’s life is spent indoor, Indoor Air Quality (IAQ) – an air quality inside of a building represented by pollutant concentration and thermal condition – is one factor that needs to be concerned to s...",
    },
    {
      id: 2,
      title:
        "Chapter 15 - Utilization of microsensors for air quality monitoring system",
      url: "https://www.researchgate.net/publication/349074688_Chapter_15_-_Utilization_of_microsensors_for_air_quality_monitoring_system?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6ImluZGV4IiwicGFnZSI6InByb2ZpbGUifX0",
      abstract:
        "Recently, the air quality monitoring system using microsensors have widely used in various environments, starting from urban and polluted air to clean areas, i.e., rural, and from ground-based stations to the high altitude. Several methods of calibration in the Laboratory for microsensors have been introducing, and some field observations under amb...",
    },
    {
      id: 3,
      title:
        "Preliminary study: health risk analysis of PM2.5 and PM10 mass concentrations in Bandung Metropolitan",
      url: "https://www.researchgate.net/publication/353561385_Preliminary_study_health_risk_analysis_of_PM25_and_PM10_mass_concentrations_in_Bandung_Metropolitan",
      abstract:
        "Particulate matters with sizes below 10 (PM10) and 2.5 (PM2.5) micrometers under polluted air have been causing not only climate change directly/indirectly but also public health problems. These were depended on the level concentrations of PM, chemical species, duration of exposure, as well as meteorological conditions, and how close we are to the...",
    },
    {
      id: 4,
      title:
        "Portable Outdoor Air Quality Monitoring Using A Wireless Sensor Network (WSN)",
      url: "https://www.researchgate.net/publication/357944426_Portable_Outdoor_Air_Quality_Monitoring_Using_A_Wireless_Sensor_Network_WSN",
      abstract:
        "Air pollution is a major environmental issue that is harmful to the living organisms and climate. The increasing level of air pollution contributed by the release of dangerous air particles are seriously degrading visibility and causing a respiratory problem to many people. Installation of air quality monitoring system in critical areas to determin...",
    },
    {
      id: 5,
      title: "Spatial Modelling of Indoor Air Pollution Distribution at Home",
      url: "https://www.researchgate.net/publication/361465842_Spatial_Modelling_of_Indoor_Air_Pollution_Distribution_at_Home",
      abstract:
        "In ancient times, humans were very accustomed to depending on nature, so that in the past humans held the title as an outdoor species. Over time with many technological advances, the pattern of human life has shifted to being an indoor species. Currently, almost 55% of the world's population lives in urban areas and is projected to increase to 68%...",
    },
    {
      id: 6,
      title:
        "Preliminary Study of Urgency to Monitor Indoor Air Quality at Telkom Education Area in the Post-Pandemic",
      url: "https://www.researchgate.net/publication/372351676_Preliminary_Study_of_Urgency_to_Monitor_Indoor_Air_Quality_at_Telkom_Education_Area_in_the_Post-Pandemic",
      abstract:
        "Indoor air pollution is found to be twice more dangerous as air pollution in the environment, especially 80-90% of people when they are indoors. Children are more susceptible to diseases caused by poor indoor air quality. Typically, students spend 60-90% of their time indoors, with most of the time at school. The targeted study areas are Telkom Edu...",
    },
    {
      id: 7,
      title:
        "Development of Smart Air Purifier for Reducing Indoor Particulate Matter",
      url: "https://www.researchgate.net/publication/370769833_Development_of_Smart_Air_Purifier_for_Reducing_Indoor_Particulate_Matter",
      abstract:
        "Indoor air quality is crucial to observe because most people spend 90% of their time in the room. Indoor air quality is influenced by various parameters, especially PM2,5, from a mixture of air outside and inside the room itself. If the occupants are exposed to this parameter continuously, it will affect the occupant’s health significantly. Hence,...",
    },
    {
      id: 8,
      title:
        "PENGEMBANGAN SISTEM PEMANTAUAN KUALITAS UDARA BERBASIS LOW-COST SENSOR DAN INTERNET OF THINGS",
      url: "https://www.researchgate.net/publication/371609947_PENGEMBANGAN_SISTEM_PEMANTAUAN_KUALITAS_UDARA_BERBASIS_LOW-COST_SENSOR_DAN_INTERNET_OF_THINGS",
      abstract:
        "Sebagai negara berkembang, Indonesia masih dilingkupi buruknya kualitas udara, baik di dalam ruangan ataupun udara ambien. Hal ini tidak terlepas dari pertumbuhan ekonomi dan daya saing usaha yang terus digenjot untuk kesejahteraan rakyat. Namun demikian, rendahnya partisipasi aktif dan kesadaran lingkungan dari masyarakat, turut memperparah keadaa...",
    },
    {
      id: 9,
      title: "SLC - Poster - i-CoMS",
      url: "https://www.researchgate.net/publication/369452312_SLC_-_Poster_-_i-CoMS",
      abstract:
        "Integrated Heavy-metal Concentrations Monitoring Systems (i-CoMS) is a sprint innovation project proposal/presentation at Science Leadership Collaborative. In brief, The Asian continent has been suffering from pollution compared to others, and it has worsened in the last five decades. The excess pollutants have been identified in the air, water, an...",
    },
    {
      id: 10,
      title: "Pemantauan Kualitas Udara Berbasis Low-Cost Sensor (LCS)",
      url: "https://www.researchgate.net/publication/369453278_Pemantauan_Kualitas_Udara_Berbasis_Low-Cost_Sensor_LCS",
      abstract:
        "Permasalahan kualitas udara terutama di Indonesia bagai cerita dalam sinetron, banyak episode dan tampak tidak berujung. Permasalahan utama dapat dilihat setidaknya sebagai berikut: ketergantungan pada teknologi utama, terbatasnya infrastruktur, kecakapan sumber daya manusia, konsistensi dalam pengamatan, kebijakan yang bias di lapangan, serta pema...",
    },
    {
      id: 11,
      title:
        "Effect of Rainfall on the Chemical Composition of Rainwater in Monitoring Acid Deposition in Greater Bandung",
      url: "https://www.researchgate.net/publication/368307990_Effect_of_Rainfall_on_the_Chemical_Composition_of_Rainwater_in_Monitoring_Acid_Deposition_in_Greater_Bandung",
      abstract:
        "Aim: The study aimed to investigate the impact of rainwater on the acidity levelsto monitor acid deposition and identify sources of pollutant that affect the acidity concentrations of rainwater. Methodology and Results: One unit of rainwater sampler in each location was employed, andsamples were taken to the National Research and Innovation Agency...",
    },
    {
      id: 12,
      title:
        "Sistem Pemantauan Temperatur dan pH Air pada Akuarium Arwana dengan Integrasi Robotic Process Automation dan Internet of Things",
      url: "https://www.researchgate.net/publication/369106939_Sistem_Pemantauan_Temperatur_dan_pH_Air_pada_Akuarium_Arwana_dengan_Integrasi_Robotic_Process_Automation_dan_Internet_of_Things",
      abstract:
        "Sebagian besar penyakit yang menjangkit ikan arwana merupakan penyakit yang diakibatkan oleh masalah kualitas air. Rendahnya temperatur maupun ketidak seimbangan nilai pH merupakan penyebab paling sering terjangkitnya penyakit pada ikan tersebut. Penelitian ini bertujuan untuk menganalisa dampak yang dapat disebabkan oleh temperatur dan pH yang ber...",
    },
    {
      id: 13,
      title:
        "Pra-Studi Analisis Paparan Konsentrasi PM2,5 dan CO2 di dalam Rumah (Preliminary Study Analysis of Exposure to PM2.5 and CO2 Concentrations in the House)",
      url: "https://www.researchgate.net/publication/367636239_Pra-Studi_Analisis_Paparan_Konsentrasi_PM25_dan_CO2_di_dalam_Rumah_Preliminary_Study_Analysis_of_Exposure_to_PM25_and_CO2_Concentrations_in_the_House",
      abstract:
        "In this COVID-19 pandemic period, the majority of people do their activities indoors. A high number of activities could increase indoor pollution. Some of the pollutants easily found in the house include particulate matter with sizes of <2.5 micrometers (PM2.5) and carbon dioxide (CO2). Both types of these pollutants can enter the body and interfer...",
    },
    {
      id: 14,
      title:
        "ALAT PEMANTAU KUALITAS UDARA DALAM RUANG BERBASIS INTERNET OF THINGS",
      url: "https://www.researchgate.net/publication/369453422_ALAT_PEMANTAU_KUALITAS_UDARA_DALAM_RUANG_BERBASIS_INTERNET_OF_THINGS",
      abstract:
        "Invensi ini mengenai kualitas udara di dalam ruangan (indoor air quality, IAQ) sangat penting untuk kesehatan manusia. Hal ini dikarenakan sebagian besar aktivitas manusia dilakukan di dalam ruangan. IAQ dapat menggambarkan kondisi termal dan kualitas udara yang ada di dalam ruangan. Kondisi termal dapat ditentukan dengan cara mengukur temperatur (...",
    },
    {
      id: 15,
      title:
        "Vertical Structure of PM 2.5 and CO 2 Concentrations in The Greater Bandung Air Basin Vertical Structure of PM2.5 and CO2 Concentrations in The Greater Bandung Air Basin",
      url: "https://www.researchgate.net/publication/365748335_Vertical_Structure_of_PM_25_and_CO_2_Concentrations_in_The_Greater_Bandung_Air_Basin_Vertical_Structure_of_PM25_and_CO2_Concentrations_in_The_Greater_Bandung_Air_Basin",
      abstract:
        "The Greater Bandung air basin, surrounded by mountains and hills, has a unique topography that could block the horizontal displacement of pollutants. Consequently, air pollution in these areas is affected by the vertical-spread pattern of pollutants. Previous studies identified that PM2.5 and CO2 concentrations in Telkom University Bandung have a s...",
    },
    {
      id: 16,
      title: "Characteristics of Acid Deposition in Urban and Sub-Urban Area",
      url: "https://www.researchgate.net/publication/364043184_Characteristics_of_Acid_Deposition_in_Urban_and_Sub-Urban_Area",
      abstract:
        "This study was conducted to examine the characteristics of acid deposition in Cipedes and Telkom University as an urban area and Tanjung Sari as a suburban area. The data used were wet deposition and dry deposition data from 2019 to 2020. Data processing was done using time series analysis. Calculation of the Fractional Acidity (FA), Neutralization...",
    },
    {
      id: 17,
      title:
        "Preliminary study of urgency to monitor indoor air quality in Telkom education area after post-pandemic",
      url: "https://www.researchgate.net/publication/371687632_Preliminary_study_of_urgency_to_monitor_indoor_air_quality_in_Telkom_education_area_after_post-pandemic",
      abstract:
        "Indoor air pollution is found to be 2x more dangerous than air pollution in the environment, especially 80-90% of people when they are indoors. Children are more susceptible to diseases caused by poor indoor air quality. Normally, students spend 60-90% of their time indoors, with most of the time at school. The targeted study areas are Telkom Educa...",
    },
    {
      id: 18,
      title:
        "Utilization of Plastic Waste as Paver Block, Economic Potential, and Environmental Impact",
      url: "https://www.researchgate.net/publication/362412798_Utilization_of_Plastic_Waste_as_Paver_Block_Economic_Potential_and_Environmental_Impact",
      abstract:
        "One of the main factors that cause environmental damage in Indonesia is waste. Reducing waste by burning it directly without an incinerator will cause air pollution for the environment. CO2 is a substance produced by a complete combustion process. The amount of waste produced by Bandung Regency reaches 1,480.1 tons/day, with 20% of the waste being...",
    },
    {
      id: 19,
      title:
        "Natural, Meteorology, And Novel – IAP Data Processing Method for Tipping Bucket Based Rain Gauge",
      url: "https://www.researchgate.net/publication/361459077_Natural_Meteorology_And_Novel_-_IAP_Data_Processing_Method_for_Tipping_Bucket_Based_Rain_Gauge",
      abstract:
        "Air pollution transport is entangled with weather and climate factors. As the pollutants tend to move due to the metrological condition. Naturally, pollutants will be deposited to the ground at the end part of the deposition cycle. One of the factors of pollutant deposition in a wet deposition is rain. The soluble pollutants and particulate matter...",
    },
    {
      id: 20,
      title:
        "Pemantauan Kualitas Udara Dalam Ruang Menggunakan Impaktor Di Gedung Deli, Universitas Telkom, Bandung (Impactor based analysis for indoor air quality monitoring system in Deli Building, Telkom University, Bandung)",
      url: "https://www.researchgate.net/publication/351523622_Pemantauan_Kualitas_Udara_Dalam_Ruang_Menggunakan_Impaktor_Di_Gedung_Deli_Universitas_Telkom_Bandung_Impactor_based_analysis_for_indoor_air_quality_monitoring_system_in_Deli_Building_Telkom_University",
      abstract:
        "Indoor Air Quality (IAQ) is an indicator of whether or not indoor air quality has an impact on human health and increases occupant comfort. According to WHO, the death rate due to health problems due to poor indoor air quality is much higher than outdoors. One of the parameters that cause a decrease in indoor air quality is the presence of microorg...",
    },
    {
      id: 21,
      title:
        "Analisis Paparan Bioaerosol Secara Personal Dalam Kegiatan Sehari-hari (Analysis of personal exposure to bioaerosol in daily activities)",
      url: "https://www.researchgate.net/publication/351523736_Analisis_Paparan_Bioaerosol_Secara_Personal_Dalam_Kegiatan_Sehari-hari_Analysis_of_personal_exposure_to_bioaerosol_in_daily_activities",
      abstract:
        "Exceeded level of gas and particulates concentrations in the air have an impact on air pollution, one of the gases is bioaerosol that it contribute up to 30% of pollution in urban environments. In general, humans can be exposed through non-biological pollutants, such as particulate matter less than 2.5 micrometers in size (PM2.5) and carbon dioxide...",
    },
    {
      id: 22,
      title:
        "Analisis Potensi Kebakaran Hutan Menggunakan Data Titik Panas dan Iklim untuk Pemasangan Alat Kualitas Udara di Provinsi Riau (Analysis of potential forest fire using hotspot and weather data for installation of air quality monitoring system in Riau Province)",
      url: "https://www.researchgate.net/publication/351523820_Analisis_Potensi_Kebakaran_Hutan_Menggunakan_Data_Titik_Panas_dan_Iklim_untuk_Pemasangan_Alat_Kualitas_Udara_di_Provinsi_Riau_Analysis_of_potential_forest_fire_using_hotspot_and_weather_data_for_insta",
      abstract:
        "Riau is one of the areas on the island of Sumatra, which has the largest peatlands, around 55.76% of the total area in Sumatra. This leaves Riau vulnerable to forest and land fires due to natural processes or land misuse. This study aims to analyze an area for the installation of air quality devices based on low-cost sensors in Riau Province by uti...",
    },
    {
      id: 23,
      title:
        "Pengaruh Ruang Terbuka Hijau Dalam Pengendalian Konsentrasi PM2.5, CO2, Dan O3 di Universitas Telkom (Impact of open green space on controlling PM2.5, CO2, and O3 concentrations in Telkom University)",
      url: "https://www.researchgate.net/publication/351523923_Pengaruh_Ruang_Terbuka_Hijau_Dalam_Pengendalian_Konsentrasi_PM25_CO2_Dan_O3_di_Universitas_Telkom_Impact_of_open_green_space_on_controlling_PM25_CO2_and_O3_concentrations_in_Telkom_University",
      abstract:
        "Green open space is an important aspect in controlling air pollution because of its ability to reduce pollutants by up to 69%. One of the big cities in Indonesia, i.e., Bandung City, only has less than 1/5 of the green open space area. This is still below the minimum limit for the total area of green open space required by the regulation. For educa...",
    },
    {
      id: 24,
      title:
        "Data validation and forecasting for long-term observation of environmental pollutions using low-cost sensors",
      url: "https://www.researchgate.net/publication/349964206_Data_validation_and_forecasting_for_long-term_observation_of_environmental_pollutions_using_low-cost_sensors",
      abstract:
        "As a developing country, Indonesia has been facing environmental issues year by year. With world rank four in population (~270 million), anthropogenic and industrial activities have dominated the emission sources; and in a specific event in the dry season, forest fire has been contributed to the level concentrations of polluted air on the regional...",
    },
    {
      id: 25,
      title:
        "Analysis of the Effect of Internal Gas Pressure of an Anaerobic Digester on Biogas Productivity of a Mixture of Cow Dung and Tofu Liquid Waste",
      url: "https://www.researchgate.net/publication/349730537_Analysis_of_the_Effect_of_Internal_Gas_Pressure_of_an_Anaerobic_Digester_on_Biogas_Productivity_of_a_Mixture_of_Cow_Dung_and_Tofu_Liquid_Waste",
      abstract:
        "Biogas is one of alternative energy for tackling the energy crisis the longer fossil-fired on the wane. Besides, biogas can cope with the problem of air pollution, such as methane gas. Pollution from methane gas can be utilized by biogas as fuel. This study discusses a brief analysis of the effect of the internal gas pressure of an anaerobic digest...",
    },
    {
      id: 26,
      title:
        'Monitoring IoT-based PM2.5 and CO2 concentrations under a policy of "working from home" in Telkom University, Bandung',
      url: "https://www.researchgate.net/publication/349730625_Monitoring_IoT-based_PM25_and_CO2_concentrations_under_a_policy_of_working_from_home_in_Telkom_University_Bandung",
      abstract:
        "The pandemic of Covid-19 detected for the first time in early March in Indonesia has been making people stay at home. It affects almost every sector, including higher education. Lecturer, staff, and students have been pushed to work and study from home. There are many approaches from the government, and the ultimate method is a full and partial loc...",
    },
    {
      id: 27,
      title:
        "Sistem Pemantauan Kualitas Udara di Kawasan Bandung Metropolitan Berbasis GSM (Air quality monitoring system based on GSM in Bandung Metropolitan Area)",
      url: "https://www.researchgate.net/publication/349728555_Sistem_Pemantauan_Kualitas_Udara_di_Kawasan_Bandung_Metropolitan_Berbasis_GSM_Air_quality_monitoring_system_based_on_GSM_in_Bandung_Metropolitan_Area",
      abstract:
        "Increased level of air pollution is one of the environmental problems that occur in big cities in Indonesia. This study aims to develop an air quality monitoring system with measuring parameters are carbon dioxide (CO2) and particulate matter (PM2.5), as well as meteorological parameters (temperature (T), relative humidity (RH), light intensity (I)...",
    },
    {
      id: 28,
      title:
        "Pemantauan Konsentrasi Gas (CO2, NO2) dan Partikulat (PM2.5) pada Struktur Horizontal di Kawasan Dayeuhkolot, Cekungan Udara Bandung Raya (Monitoring of Horizontal Structure of Gases (CO2, NO2) and Particulate (PM2.5) Concentrations in Dayeuhkolot, Greater Bandung Air Basin)",
      url: "https://www.researchgate.net/publication/348329441_Pemantauan_Konsentrasi_Gas_CO2_NO2_dan_Partikulat_PM25_pada_Struktur_Horizontal_di_Kawasan_Dayeuhkolot_Cekungan_Udara_Bandung_Raya_Monitoring_of_Horizontal_Structure_of_Gases_CO2_NO2_and_Particulate_P",
      abstract:
        "Indra Chandra indrachandra@telkomuniversity. ac.id ABSTRAK Salah satu sumber polusi lokal yang terjadi di Bandung Raya berasal dari kendaraan bermotor. Dari penelitian sebelumnya terindikasi bahwa konsentrasi organic carbon (OC) lebih tinggi dari elemental carbon (EC), konsentrasi OC primer lebih tinggi dari OC sekunder, dan konsentrasi char-EC leb...",
    },
    {
      id: 29,
      title:
        "Change management to support institutional amendment of higher education based on Kotter's model",
      url: "https://www.researchgate.net/publication/348023562_Change_management_to_support_institutional_amendment_of_higher_education_based_on_Kotter's_model",
      abstract: "",
    },
    {
      id: 30,
      title:
        "Rancang Bangun Alat Ukur Konsentrasi Massa PM2.5 Berbasis Optik (Design and implementation of an optical-based detector PM2.5 mass concentrations)",
      url: "https://www.researchgate.net/publication/351523309_Rancang_Bangun_Alat_Ukur_Konsentrasi_Massa_PM25_Berbasis_Optik_Design_and_implementation_of_an_optical-based_detector_PM25_mass_concentrations",
      abstract:
        "Bandung Raya has a unique topography. It is surrounded by mountains forming a basin which results in pollutant trappings. Besides conventional instruments such as TEOM which is pricy, lowcost sensors are often used as an alternative to detect pollutant particles of PM10 to PM1.0 because of their relatively lower cost and portable size. However, low...",
    },
    {
      id: 31,
      title:
        "Rancang Bangun Alat Ukur Konsentrasi Gas CO2 dan NO2 untuk Pengamatan Emisi dari Pembakaran Sampah Rumah Tangga (Design of CO2 and NO2 gas detector for monitoring emissions from residential-waste burning)",
      url: "https://www.researchgate.net/publication/338935296_Rancang_Bangun_Alat_Ukur_Konsentrasi_Gas_CO2_dan_NO2_untuk_Pengamatan_Emisi_dari_Pembakaran_Sampah_Rumah_Tangga_Design_of_CO2_and_NO2_gas_detector_for_monitoring_emissions_from_residential-waste_burni",
      abstract:
        "Direct combustion of waste as a short-term solution to reduce its volume in residential areas will cause a new problem, i.e. air pollution, if not used the correct incineration method. Pollutants that can be produced from waste burning are, for example, gas (Carbon Dioxide/CO2 and Nitrogen Dioxide/NO2) and particulate (size of less than 2.5 microme...",
    },
    {
      id: 32,
      title:
        "Potensi Kadar Konsentrasi CO2 dan PM2.5 yang Dihasilkan dari Pembakaran Sampah Organik dan Anorganik Menggunakan Insinerator (Potential levels of CO2 and PM2.5 concentrations resulting from burning organic and inorganic waste using incinerator)",
      url: "https://www.researchgate.net/publication/338935528_Potensi_Kadar_Konsentrasi_CO2_dan_PM25_yang_Dihasilkan_dari_Pembakaran_Sampah_Organik_dan_Anorganik_Menggunakan_Insinerator_Potential_levels_of_CO2_and_PM25_concentrations_resulting_from_burning_organ",
      abstract:
        "One of the emission sources of polluted air from anthropogenic is waste combustion which can produce gas (i.e. carbon dioxide / CO2) and/or particulate (PM2.5) under low/high temperatures conditions. Therefore, a waste management system using an incinerator is one of a method to burn waste with an ability that it can filter those emissions. In this...",
    },
    {
      id: 33,
      title:
        "Analisis Kualitas Udara Dalam Ruangan pada Kantor Terbuka di Universitas Telkom (Indoor air quality analysis of open offices in Telkom University)",
      url: "https://www.researchgate.net/publication/341833171_Analisis_Kualitas_Udara_Dalam_Ruangan_pada_Kantor_Terbuka_di_Universitas_Telkom_Indoor_air_quality_analysis_of_open_offices_in_Telkom_University",
      abstract:
        "The research was conducted on four open offices in the Telkom University area, measurements for every office are done for 24 hours, and two times (in the morning and the afternoon) for the biological parameters. The non-biological measuring devices used for the research are low-cost based sensors which are PM2.5 sensors, CO2 sensors, and temperatur...",
    },
    {
      id: 34,
      title:
        "Prastudi Pemantauan Bioaerosol di Dalam Ruangan dan Analisisnya (Prestudy of monitoring indoor bioaerosol and its analysis)",
      url: "https://www.researchgate.net/publication/341833266_Prastudi_Pemantauan_Bioaerosol_di_Dalam_Ruangan_dan_Analisisnya_Prestudy_of_monitoring_indoor_bioaerosol_and_its_analysis",
      abstract:
        "Bioaerosol is microorganisms with the size of 0,02-100 µm in the air. Humans do a lot of activities in the room, so the indoor air quality is importan factor that must be consider. Bioaerosol formed of bacteria, viruses, fungi and allergance such as dust parasites that can be sourced from dead carcasses and dust mites. The impact on health mainly i...",
    },
    {
      id: 35,
      title:
        "Pemantauan Konsentrasi PM2.5 dan CO2 Berbasis Low-Cost Sensor secara Real-Time di Cekungan Udara Bandung Raya (Real-Time Monitoring of PM2.5 and CO2 Concentrations Based on Low- Cost Sensor in The Greater Bandung Air Basin)Jurnal",
      url: "https://www.researchgate.net/publication/338884163_Pemantauan_Konsentrasi_PM25_dan_CO2_Berbasis_Low-Cost_Sensor_secara_Real-Time_di_Cekungan_Udara_Bandung_Raya_Real-Time_Monitoring_of_PM25_and_CO2_Concentrations_Based_on_Low-_Cost_Sensor_in_The_Greate",
      abstract:
        "Field observation of PM2.5 and CO2 concentrations and meteorological conditions using low-cost sensors in real-time was carried out in the Greater Bandung air basin on Mar. 12 – Apr. 25, 2019. PM2.5 and CO2 sensors, as well as detectors of meteorological parameters, have been calibrated in the Laboratory. The instruments were placed in two location...",
    },
    {
      id: 36,
      title:
        "A Design and Performance of Low-Cost Blender for Improving Methane Gas Production",
      url: "https://www.researchgate.net/publication/350510152_A_Design_and_Performance_of_Low-Cost_Blender_for_Improving_Methane_Gas_Production",
      abstract:
        "Biogas from cow dung is alternative energy in overcoming the energy crisis. One of the simpler technologies in biogas is an anaerobic \ndigester made of cow dung as a substrate. Cow dung will optimally produce biogas by reducing the particle size of the substrate.\nResearch has been carried out by designing a low-cost blender and low-energy device. T...",
    },
    {
      id: 37,
      title:
        "Pemantauan Konsentrasi Gas (CO2, NO2) dan Partikulat (PM2.5) pada Struktur Horizontal di Kawasan Dayeuhkolot, Cekungan Udara Bandung Raya",
      url: "https://www.researchgate.net/publication/338914101_Pemantauan_Konsentrasi_Gas_CO2_NO2_dan_Partikulat_PM25_pada_Struktur_Horizontal_di_Kawasan_Dayeuhkolot_Cekungan_Udara_Bandung_Raya",
      abstract:
        "Greater Bandung has one of the main sources of local air pollution, i.e. the traffic. Based on previous studies, organic carbon (OC), primary OC, and char-elemental carbon (char-EC) concentrations are higher than EC, secondary OC, and soot-EC respectively. A high concentration of OC is caused by diesel engines while primary OC is directly emitted b...",
    },
    {
      id: 38,
      title:
        "Pemantauan Konsentrasi Gas (CO2 , NO2 ) dan Partikulat (PM2.5 ) pada Struktur Horizontal di Kawasan Dayeuhkolot, Cekungan Udara Bandung Raya",
      url: "https://www.researchgate.net/publication/338914174_Pemantauan_Konsentrasi_Gas_CO2_NO2_dan_Partikulat_PM25_pada_Struktur_Horizontal_di_Kawasan_Dayeuhkolot_Cekungan_Udara_Bandung_Raya",
      abstract:
        "Greater Bandung has one of the main sources of local air pollution, i.e. the traffic. Based on previous studies, organic carbon (OC), primary OC, and char-elemental carbon (char-EC) concentrations are higher than EC, secondary OC, and soot-EC respectively. A high concentration of OC is caused by diesel engines while primary OC is directly emitted b...",
    },
    {
      id: 39,
      title:
        "Observasi Lapangan Mikropartikel di Atmosfer Menggunakan Nanosampler pada Cekungan Udara Bandung Raya (Field observation of atmospheric microparticles using Nanosampler in the Greater Bandung air basin)",
      url: "https://www.researchgate.net/publication/333448718_Observasi_Lapangan_Mikropartikel_di_Atmosfer_Menggunakan_Nanosampler_pada_Cekungan_Udara_Bandung_Raya_Field_observation_of_atmospheric_microparticles_using_Nanosampler_in_the_Greater_Bandung_air_basin",
      abstract:
        "Abstrak Kampanye pengamatan mikro-partikel telah dilakukan di cekungan udara Bandung Raya pada periode musim hujan (14-26 Februari 2018) dan kemarau (17 Agustus-11 September 2018). Pengamatan mikro-partikel menggunakan Nanosampler dengan ukuran >10, 2,5-10, 1-2,5, 0,5-1, 0,1-0,5 dan <0,1 µm. Hasil dari nanosampler adalah konsentrasi massa partikula...",
    },
    {
      id: 40,
      title:
        "Pengamatan Konsentrasi Massa PM2.5 di Cekungan Udara Bandung Raya (Observation of PM2.5 mass concentrations in the Greater Bandung air basin)",
      url: "https://www.researchgate.net/publication/333448823_Pengamatan_Konsentrasi_Massa_PM25_di_Cekungan_Udara_Bandung_Raya_Observation_of_PM25_mass_concentrations_in_the_Greater_Bandung_air_basin",
      abstract:
        "PM2.5 adalah partikulat yang tersuspensi di udara dan berukuran < 2.5 µm. Apabila konsentrasinya melebihi ambang batas maka dapat membahayakan kesehatan manusia, hewan, dan/atau tumbuhan. Penelitian ini bertujuan untuk membuat alat ukur konsentrasi massa PM2.5 dengan menggunakan low-cost sensor di cekungan udara Bandung Raya dan menganalisis pengar...",
    },
    {
      id: 41,
      title:
        "Rancang Bangun Portable Weather Station dalam Mendukung Pengamatan Mikropartikel di Cekungan Udara Bandung Raya (Design of portable weather station to support microparticle observation in the Greater Bandung air basin)",
      url: "https://www.researchgate.net/publication/333449030_Rancang_Bangun_Portable_Weather_Station_dalam_Mendukung_Pengamatan_Mikropartikel_di_Cekungan_Udara_Bandung_Raya_Design_of_portable_weather_station_to_support_microparticle_observation_in_the_Greater_B",
      abstract:
        "Development of portable weather station for monitoring microparticles in Greater Bandung air basin is very important to measure meteorological conditions and to identify potential sources of polluted air. We used temperature and relative humidity sensors (DHT22), air pressure sensors (BMP180), light intensity sensors \n(BH1750), wind speed sensors (...",
    },
    {
      id: 42,
      title:
        "Rancang Bangun Alat Uji Tarik untuk Karakterisasi Sifat Mekanik dan Listrik pada Material Konduktif Fleksibel (Development of tensile test machine for mechanical and electrical properties characterization of flexible conductive material)",
      url: "https://www.researchgate.net/publication/351523507_Rancang_Bangun_Alat_Uji_Tarik_untuk_Karakterisasi_Sifat_Mekanik_dan_Listrik_pada_Material_Konduktif_Fleksibel_Development_of_tensile_test_machine_for_mechanical_and_electrical_properties_characterizat",
      abstract:
        "Elektronik fleksibel merupakan sebuah peranti elektronik yang memanfaatkan material konduktif atau semikonduktor yang dideposisi di atas substrat fleksibel. Karena bentuknya yang mudah diubah, elektronik fleksibel memiliki banyak kegunaan dalam kehidupan sehari-hari, contohnya, sebagai sensor elastis yang dapat diletakkan pada pakaian atau organ tu...",
    },
    {
      id: 43,
      title:
        "Development of low-cost logic-gates based on the pneumatic system using an automatic controller for education in engineering physics",
      url: "https://www.researchgate.net/publication/332840790_Development_of_low-cost_logic-gates_based_on_the_pneumatic_system_using_an_automatic_controller_for_education_in_engineering_physics",
      abstract:
        "To comply international-accreditation requirement in program study of engineering physics for undergraduate students, Telkom University, we initiated student center learning (SCL) to achieve course learning outcomes (CLO) through Automatic Control course. We developed automatic controller for low-cost logic-gates based on the pneumatic. The pneumat...",
    },
    {
      id: 44,
      title:
        "Estimating the influence of transport on aerosol size distributions during new particle formation events",
      url: "https://www.researchgate.net/publication/329128723_Estimating_the_influence_of_transport_on_aerosol_size_distributions_during_new_particle_formation_events",
      abstract:
        "New particle formation (NPF) and subsequent particle growth occur\nfrequently in various atmospheric environments. Significant influence of\ntransport on aerosol size distributions is commonly observed, especially for\nnon-regional NPF events. With certain assumptions and approximations, a\npopulation balance method is proposed to examine the influence...",
    },
    {
      id: 45,
      title:
        "Current situation of atmospheric nanoparticles in Fukue Island, Japan",
      url: "https://www.researchgate.net/publication/328523111_Current_situation_of_atmospheric_nanoparticles_in_Fukue_Island_Japan",
      abstract:
        "Emissions of polluted air in East Asia have gradually decreased over the last decade. Those air pollutants have been transported over long distances and influenced new particle formation (NPF) in the downstream region. We obtained 5-year data of the mobility size distribution and SO2 and particulate (PM2.5) emissions on Fukue Island (32.75°N, 128.6...",
    },
    {
      id: 46,
      title:
        "Estimating the influence of transport to aerosol size distributions during new particle formation events",
      url: "https://www.researchgate.net/publication/327512665_Estimating_the_influence_of_transport_to_aerosol_size_distributions_during_new_particle_formation_events",
      abstract:
        "New particle formation (NPF) and the subsequent particle growth occur frequently in various atmospheric environments. Significant influence of transport on aerosol size distributions is commonly observed, especially for non-regional NPF events. With certain assumptions and approximations, a population balance method is proposed to examine the influ...",
    },
    {
      id: 47,
      title:
        "Aerial Observation of Atmospheric Nanoparticles in Fukue Island, Japan",
      url: "https://www.researchgate.net/publication/329575113_Aerial_Observation_of_Atmospheric_Nanoparticles_in_Fukue_Island_Japan",
      abstract:
        "New particle formation (NPF) in the East Asia region is strongly influenced by the photochemical processes during long-range transport of air pollutants. Our previous measurements (Chandra et al., 2016; Seto et al., 2013) related to the incomplete and weak NPF (onset diameter above 10 nm) at Fukue Island in Japan (downstream from the Asian continen...",
    },
    {
      id: 48,
      title:
        "Current situation of atmospheric nanoparticles in Fukue Island, Japan",
      url: "https://www.researchgate.net/publication/317401847_Current_situation_of_atmospheric_nanoparticles_in_Fukue_Island_Japan",
      abstract: "",
    },
    {
      id: 49,
      title:
        "New particle formation under the influence of the long-range transport of air pollutants in East Asia",
      url: "https://www.researchgate.net/publication/304338524_New_particle_formation_under_the_influence_of_the_long-range_transport_of_air_pollutants_in_East_Asia",
      abstract: "",
    },
    {
      id: 50,
      title: "Supplementary materials - NPF",
      url: "https://www.researchgate.net/publication/304394914_Supplementary_materials_-_NPF",
      abstract: "",
    },
    {
      id: 51,
      title:
        "Observation of atmospheric nanoparticles using TSI-1nm-SMPS at Fukue Island, Japan",
      url: "https://www.researchgate.net/publication/307558686_Observation_of_atmospheric_nanoparticles_using_TSI-1nm-SMPS_at_Fukue_Island_Japan",
      abstract:
        "In order to investigate the new particle formation (NPF) down to 1 nm, we conducted the field observation at Fukue site (32.8ºN, 128.7ºE) from Feb. 27 to Mar. 25, 2016, using a TSI-1nm-SMPS. The NPF event with onset diameters as small as 5 nm was identified on Mar. 2 under peak of solar radiation (826 W·m-2) and relatively high pressure (1030 hPa)...",
    },
    {
      id: 52,
      title:
        "Observation of atmospheric nanoparticles using TSI-1nm-SMPS at Fukue Island, Japan",
      url: "https://www.researchgate.net/publication/307559423_Observation_of_atmospheric_nanoparticles_using_TSI-1nm-SMPS_at_Fukue_Island_Japan",
      abstract:
        "In order to investigate the new particle formation (NPF) down to 1 nm, we conducted the field observation at Fukue site (32.8ºN, 128.7ºE) from Feb. 27 to Mar. 25, 2016, using a TSI-1nm-SMPS. The NPF event with onset diameters as small as 5 nm was identified on Mar. 2 under peak of solar radiation (826 W·m-2) and relatively high pressure (1030 hPa)...",
    },
    {
      id: 53,
      title: "Measurement of Atmospheric Nanoparticles",
      url: "https://www.researchgate.net/publication/304629171_Measurement_of_Atmospheric_Nanoparticles",
      abstract:
        "As requested talk in The Society of Chemical Engineers, Japan, 81st Annual Meeting 2016, to present about measurement of atmospheric nano-particles based on manuscript of Chandra et al. (2016, http://dx.doi.org/10.1016/j.atmosenv.2016.06.040).",
    },
  ];
};

export { publicationData };