import React, { useEffect, useState, useCallback, useRef } from "react"; // Make sure useRef is imported
import { Container, Box, Grid } from "@mui/material";
import axios from "axios";
import ForecastTableTULT from "./TableForecastTULT";
import ChartAQMSTULT from "../AQMSTULT/ChartAQMSTULT";
import App from "../../../Components/Windrose/Windrose";

function StationAQMSTULT() {
  const [res, setRes] = useState(null);
  const intervalIdRef = useRef(null); // Use useRef to store intervalId

  const fetchData = useCallback(async () => {
    try {
      const url = 'https://biru-langit.com/api/TULT/2m';
      const response = await axios.get(url);
      setRes(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    const initiateFetch = () => {
      fetchData();

      const now = new Date();
      const secondsUntilNextFetch = (61 - now.getSeconds()) % 60;
      const timeoutId = setTimeout(() => {
        fetchData();
        intervalIdRef.current = setInterval(fetchData, 60000); // Store intervalId in useRef
      }, secondsUntilNextFetch * 1000);

      return () => {
        clearTimeout(timeoutId);
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current); // Cleanup on unmount or dependency change
        }
      };
    };

    initiateFetch();
  }, [fetchData]);

  return (
    <div>
      <section className="bg">
        <Container sx={{ pt: 12, pb: 5 }} maxWidth="md">
          <ChartAQMSTULT res={res} />
        </Container>
        <Box
          sx={{
            backgroundColor: "#152b56",
            display: "flex",
            justifyContent: "center",
            padding: 2,
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} lg={6}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <App />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ForecastTableTULT />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </section>
    </div>
  );
}

export default StationAQMSTULT;
