import React, { useEffect, useState } from "react";
import "./StationTower.scss";
import { Container } from "@mui/material";
import Map from "../../../Components/Map/Map";
import axios from "axios";
import ChartAQMSTower from "./ChartAQMSTower";
// import { DataTower } from "./DataTower";

function StationTower() {
  // const data = DataTower();
  const [res, setRes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data based on the parameter state
      const url = `https://biru-langit.com/api/towers/data/`;
      const response = await axios.get(url); 
      setRes(response);
      // console.log(response);
    };
    fetchData();
  }, []);
  return (
    <div>
      <section className="bg">
        <Container sx={{ pt: 12, pb: 5 }} maxWidth="md">
          <ChartAQMSTower res={res}/>
        </Container>
      </section>
      {/* 
      <Map res={res} data={data}/>
      */}
    </div>
  );
}

export default StationTower;
