import { Grid } from "@mui/material";
import { FaFaceFrownOpen, FaFaceLaughBeam, FaFaceMeh } from "react-icons/fa6";

function IndicatorGrid({ standard, name }) {
  //mapping indicator
  const ind = [
    {
      class: "low",
      title: "Low",
      range: `< ${standard.low}`,
      icon: <FaFaceLaughBeam className='ind-icon' />,
    },
    {
      class: "mid",
      title: "Medium",
      range: `${standard.low} - ${standard.high}`,
      icon: <FaFaceMeh className='ind-icon' />,
    },
    {
      class: "high",
      title: "High",
      range: `> ${standard.high} `,
      icon: <FaFaceFrownOpen className='ind-icon' />,
    },
  ];
  if (["Pressure", "Temperature", "Humidity", "Wind Speed", "Wind Direction", "Pitch", "Roll"].includes(name)) {
    return null; // Return null when name is "pressure", "temperature," or "humidity"
  } else {
    return (
      standard.high !== "" &&
      standard.low !== "" && (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {ind.map((item, i) => (
            <Grid item xs={4} key={i}>
              <div className={`chip ${item.class}`}>
                <p className='range'>{item.range}</p>
                <p>
                  <b>{item.title}</b>
                </p>
                {item.icon}
              </div>
            </Grid>
          ))}
        </Grid>
      )
    );
  }
}

export default IndicatorGrid;
