import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

function SopLab() {
  const [selectedPdfLaboratorium, setSelectedPdfLaboratorium] = useState("");
  const [selectedPdfLapangan, setSelectedPdfLapangan] = useState("");

  const handleChangeLaboratorium = (event) => {
    setSelectedPdfLaboratorium(event.target.value);
    setSelectedPdfLapangan(""); // Reset Lapangan selection
  };

  const handleChangeLapangan = (event) => {
    setSelectedPdfLapangan(event.target.value);
    setSelectedPdfLaboratorium(""); // Reset Laboratorium selection
  };

  const pdfButtonsLab = [
    { label: "Dashboard", url: "Assets/SOPLab/DASHBOARD.pdf" },
    { label: "EWS", url: "Assets/SOPLab/EWS.pdf" },
    { label: "Kalibrasi Sensor", url: "Assets/SOPLab/KALIBRASI SENSOR.pdf" },
    { label: "Pembelian Alat", url: "Assets/SOPLab/PEMBELIAN ALAT.pdf" },
    { label: "Prediksi Data", url: "Assets/SOPLab/PREDIKSI DATA.pdf" },
    { label: "Preparasi Alat", url: "Assets/SOPLab/PREPARASI ALAT.pdf" },
    { label: "Rangkai Alat Ukur", url: "Assets/SOPLab/RANGKAI ALAT UKUR.pdf" },
    { label: "Validasi Data", url: "Assets/SOPLab/VALIDASI DATA.pdf" },
    { label: "Visualisasi Data", url: "Assets/SOPLab/VISUALISASI DATA.pdf" },
  ];

  const pdfButtonsLap = [
    { label: "Pemasangan Alat", url: "Assets/SOPLap/PEMASANGAN ALAT.pdf" },
    { label: "Tower", url: "Assets/SOPLap/TOWER.pdf" },
    { label: "Uji Koloasi", url: "Assets/SOPLap/UJI KOLOKASI.pdf" },
  ];

  return (
    <div>
      <Container sx={{ pb: 10 }}>
        <Typography textAlign="left" variant="h1">
          SOP Document
        </Typography>
        <Typography variant="subtitle1" textAlign="left" marginBottom={"10px"}>
          Pedoman Operasional Standar
        </Typography>
        <FormControl variant="outlined" sx={{ marginBottom: "20px" }}>
          <InputLabel id="pdf-select-lab">SOP Laboratorium</InputLabel>
          <Select
            labelId="pdf-select-label-lab"
            id="pdf-select-lab"
            value={selectedPdfLaboratorium}
            onChange={handleChangeLaboratorium}
            style={{ minWidth: 250 }}
            label="SOP Laboratorium"
          >
            {pdfButtonsLab.map((button, index) => (
              <MenuItem key={index} value={button.url}>
                {button.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ marginBottom: "20px" }}>
          <InputLabel id="pdf-select-lap">SOP Lapangan</InputLabel>
          <Select
            labelId="pdf-select-label-lap"
            id="pdf-select-lap"
            value={selectedPdfLapangan}
            onChange={handleChangeLapangan}
            style={{ minWidth: 250 }}
            label="SOP Lapangan"
          >
            {pdfButtonsLap.map((button, index) => (
              <MenuItem key={index} value={button.url}>
                {button.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Card
            className="pdfCard"
            style={{
              width: 2000,
              height: "600",
              backgroundColor: "#D6E6F2",
              padding: "2px",
              color: "white",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent className="sopCard">
              <iframe
                src={selectedPdfLaboratorium || selectedPdfLapangan}
                width="100%"
                height="580"
              ></iframe>
            </CardContent>
          </Card>
        </div>
      </Container>
    </div>
  );
}

export default SopLab;