import React, { useState } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Divider,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { BsEyeFill, BsEyeSlashFill, BsGoogle } from "react-icons/bs";
import thumbnail from "../../imgAVIF/Rectangle-4.avif";
import axios from "axios";

export default function Register() {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await axios.post("https://biru-langit.com/api/dashboard/register", {
        full_name: name,
        username: username,
        password: password,
        token: token,
        role: "user", // or 'admin', based on your requirement
      });

      if (response.data.token) {
        setSuccess("Registration successful! You can now log in.");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setError("Validasi Token gagal, Pastikan Token anda sudah benar");
      } else if (err.response && err.response.status === 409) {
        setError(
          "Username sudah ada, silahkan gunakan username lain."
        );
      } else if (err.response && err.response.status === 410) {
        setError(
          "Nama Lengkap sudah dipakai, silahkan gunakan nama Lengkap yang lain."
        );
      } else if (err.response && err.response.status === 411) {
        setError("Invalid token provided. Please check your token.");
      } else {
        setError(err.response?.data?.message || "Registration failed.");
      }
    }
  };

  return (
    <div className="bg">
      <Container sx={{ pt: 20, pb: 10 }} maxWidth="md">
        <div className="box-auth">
          <Grid container justifyContent="center">
            <Grid
              className="plane-img"
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                backgroundImage: `url(${thumbnail})`,
              }}
            />
            <Grid item md={6} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 5,
                }}
              >
                <Typography component="h1" variant="h4">
                  Hello There!
                </Typography>
                <Typography component="h5">
                  Are you ready for your first course?
                </Typography>
                <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
                  <TextField
                    margin="dense"
                    autoComplete="given-name"
                    name="Full Name"
                    required
                    fullWidth
                    id="Full Name"
                    label="Full Name"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="username"
                    label="username"
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />

                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    name="token"
                    label="Token"
                    id="token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                  />

                  {error && <Alert severity="error">{error}</Alert>}
                  {success && <Alert severity="success">{success}</Alert>}

                  <Button
                    type="submit"
                    disabled={!name || !username || !password || !token}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Register
                  </Button>

                  <Divider>or continue with</Divider>
                  <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    <BsGoogle style={{ marginRight: "10px" }} />
                    Google
                  </Button>

                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href="/login" variant="body2">
                        Already have an account? Login
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
