import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import merah from "../../imgAVIF/merah.avif";
import hijau from "../../imgAVIF/Hijau.avif";
import kuning from "../../imgAVIF/kuning.avif";

const FlaggingSystem = () => {
  const theme = useTheme();
  const [station, setStation] = useState("All Station");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const handleStationChange = (event) => {
    const selectedStation = event.target.value;
    setStation(selectedStation);
  
    if (selectedStation === "All Station") {
      // Sort rows by lastActive time (start check)
      const sortedRows = rows.sort((a, b) => new Date(a.lastActive) - new Date(b.lastActive));
      setFilteredRows(reindexRows(sortedRows)); // Reindex rows after sorting
    } else {
      const filtered = rows.filter((row) => row.station === selectedStation);
      setFilteredRows(reindexRows(filtered)); // Reindex filtered rows
    }
  };  

  const reindexRows = (data) => {
    return data.map((item, index) => ({
      ...item,
      no: index + 1, // Reindex based on filtered data
    }));
  };

  const columns = [
    { field: "no", headerName: "No", flex: 0.5, minWidth: 90, headerAlign: "center", align: "center" },
    {
      field: "station",
      headerName: "Station",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Select value={station} onChange={handleStationChange} displayEmpty sx={{ width: "100%", textAlign: "center" }}>
          <MenuItem value="All Station">All</MenuItem>
          <MenuItem value="TULT">TULT</MenuItem>
          <MenuItem value="GKU">GKU</MenuItem>
          <MenuItem value="Deli">Deli</MenuItem>
        </Select>
      ),
    },
    { field: "lastActive", headerName: "Start Check", flex: 1, minWidth: 180, headerAlign: "center", align: "center" },
    { field: "lastOff", headerName: "End Check", flex: 1, minWidth: 180, headerAlign: "center", align: "center" },
    {
      field: "flagNotif",
      headerName: "Flag Notif",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <img src={params.row.flagIcon} alt="Flag" width="30" height="30" />,
    },
    {
      field: "keterangan",
      headerName: "Keterangan",
      flex: 2,
      minWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography variant="body2" noWrap={false} sx={{ whiteSpace: "normal", textAlign: "center" }}>
          {params.value}
        </Typography>
      ),
    },
    { field: "parameter", headerName: "Parameters", flex: 1, minWidth: 150, headerAlign: "center", align: "center" },
  ];

  useEffect(() => {
    const stations = ["GKU", "TULT", "Deli"];
    const fetchFlaggingData = async () => {
      try {
        const updatedDataPromises = stations.map(async (station) => {
          const response = await fetch(`https://biru-langit.com/api/Flagging/${station}`);
          const { data } = await response.json();

          return data.map((item, index) => {
            let flagIcon;
            const flagNotif = item["Flag Notif"];

            if (flagNotif === 0) {
              flagIcon = hijau;
            } else if (flagNotif === 1) {
              flagIcon = kuning;
            } else if (flagNotif === 2) {
              flagIcon = merah;
            }

            return {
              id: `${station}-${index + 1}`,
              no: index + 1, // Initial numbering (will be reindexed)
              station: station,
              lastActive: item["Start Check"] || "N/A",
              lastOff: item["End Check"] || "N/A",
              flagNotif: flagNotif,
              flagIcon,
              keterangan: item.Keterangan || "No description available",
              parameter: "All",
            };
          });
        });

        const allData = reindexRows((await Promise.all(updatedDataPromises)).flat());
        setRows(allData);
        setFilteredRows(allData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFlaggingData();
  }, []);

  return (
    <Box m="20px">
      <Typography variant="h1" gutterBottom fontWeight="semi-bold" align="left">
        Flagging System
      </Typography>

      <Box width="100%" sx={{ overflowX: "auto" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          autoHeight
          disableExtendRowFullWidth
          pagination
          pageSizeOptions={[15, 30, 50]} // Pilihan jumlah baris
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 }, // Default 15 baris per halaman
            },
          }}
          sx={{
            "& .MuiDataGrid-root": {
              width: "100%",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#D6E6F2",
              color: theme.palette.common.black,
              fontWeight: "bold",
              textAlign: "center",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid #e0e0e0",
              backgroundColor: "#f9f9f9",
              textAlign: "center",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#D6E6F2",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FlaggingSystem;
