//chartJS data
import {
  Chart as ChartJS,
  registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

function ChartComponent({ data }) {
  const commonOption = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    height: "350px",
    width: "720px",
  };
  return (
    <div className='chart-wrapper'>
      <div className='chart'>
        {data ? (
            <Bar data={data} options={commonOption} />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <h3 style={{ letterSpacing: "1px" }}>Getting the data...</h3>
            <p style={{ letterSpacing: "1px", margin: "0" }}>
              Please wait for a moment!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChartComponent;
