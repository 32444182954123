// VisitOverview.jsx
import { Box, Typography } from "@mui/material";
import LineChartVisit from "../../Components/Dashboard/ComponentDashboard/LineChartVisit.jsx";
import BarChartVisit from "../../Components/Dashboard/ComponentDashboard/BarChartVisit.jsx";

const VisitOverview = () => {
  return (
    <Box p={2} style={{ backgroundColor: "#fff" }}>
      <Typography variant="h1" fontWeight="bold" gutterBottom>
        Visit Overview
      </Typography>

      {/* Line Chart for Visit Trend */}
      <LineChartVisit />

      {/* Bar Chart for Visit Distribution by Country */}
      <BarChartVisit />
    </Box>
  );
};

export default VisitOverview;
