import Artikel from '../../Components/artikel/artikel';
import Kebencanaan from './Kebencanaan';

function InfoKebencanaan() {
  return (
    <>
      <Kebencanaan />
      <Artikel />
    </>
  );
}

export default InfoKebencanaan;
