import React from "react";
// import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
// import { BsSearch } from "react-icons/bs";
import "./AboutAnR.scss";

import about from "../../imgAVIF/Rectangle-4.avif";

function AboutAnR() {
  // const navigate = useNavigate();

  return (
    <div>
      <section className="top-anr bg">
        <Container sx={{ pt: 20, pb: 10 }} maxWidth="md" className="con-anr">
          <Grid container alignItems="center" className="sm-reverse">
            <Grid
              item
              xs={12}
              sm={10}
              md={6}
              lg={6}
              xl={6}
              className="grid-text"
            >
              <h1>
                Would like to know more about{" "}
                <span>Environtmental pollutions</span> ?
              </h1>

              {/* <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  minWidth: "270px",
                  fontSize: "18px",
                  fontWeight: "700",
                  letterSpacing: "3px",
                }}
                startIcon={<BsSearch />}
                onClick={() => navigate("/info-kebencanaan")}
              >
                Check This Out!
              </Button> */}
            </Grid>
            <Grid item xs={12} sm={10} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid item xs={12} className="hero-text radon">
                  <h3>What is Radon?</h3>
                  <p>
                    Radon is a naturally occurring gas that can be found in
                    varying concentrations in soil, rock, and water. Radon
                    levels can vary depending on the geographical location,
                    geological composition of the soil, and the weather
                    conditions.
                  </p>
                </Grid>
                <Grid item xs={12} className="hero-text air">
                  <h3>What is Air Quality?</h3>
                  <p>
                    Air quality is the condition of the air in a specific
                    environment, including the presence of pollutants,
                    particulate matter, and other harmful substances that can
                    affect human health and the environment.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            className="box impact-box"
            sx={{ mt: 3 }}
          >
            <Grid
              className="image round-left"
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              xl={5}
              style={{
                backgroundImage: `url(${about})`,
                minHeight: "150px",
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              sx={{ px: 7, py: 5 }}
            >
              <h3>Air Pollution Impact</h3>
              <p>
                Air pollution can have a wide range of negative impacts on both
                human health and the environment. First, air pollution can lead
                to respiratory problems such as asthma, lung cancer, and chronic
                obstructive pulmonary disease. It can also exacerbate existing
                conditions such as heart disease and diabetes. Air pollution can
                also contribute to climate change by trapping heat in the
                atmosphere, leading to rising global temperatures, melting of
                ice caps and glaciers, and more extreme weather events like
                hurricanes, typhoons, and cyclones. Air pollution can also harm
                ecosystems by damaging plant life and reducing biodiversity. It
                can also cause acid rain, which can have devastating effects on
                forests, lakes, and other ecosystems.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            className="box impact-box sm-reverse"
            sx={{ mt: 5 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              sx={{ px: 7, py: 5 }}
            >
              <h3>Radon Exposure Impact</h3>
              <p>
                Exposure to high levels of radon can have several negative
                impacts on human health, including: Lung cancer: Radon is the
                leading cause of lung cancer among non-smokers and is
                responsible for around 10% of all lung cancer deaths. When radon
                gas is inhaled, it can damage the lungs and cause cancer.
                Respiratory problems: Exposure to radon can also cause
                respiratory problems such as coughing, wheezing, and shortness
                of breath. Cardiovascular disease: Some studies have suggested
                that exposure to radon may increase the risk of cardiovascular
                disease, although more research is needed to confirm this.
              </p>
            </Grid>

            <Grid
              className="image round-right "
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              style={{
                minHeight: "150px",
                backgroundImage: `url(${about})`,
              }}
            ></Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
}

export default AboutAnR;
