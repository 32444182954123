import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { BsSearch } from "react-icons/bs";

function SearchBar({ onSearch }) {
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    onSearch(searchText);
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    onSearch(text); // Execute search on input change
  };

  return (
    <TextField
      id="outlined"
      placeholder="Search a publication"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <BsSearch title="search" className="icon" />
          </InputAdornment>
        ),
      }}
      sx={{ mt: 3, maxWidth: "500px", width: "100%" }}
      value={searchText}
      onChange={handleInputChange}
    />
  );
}

export default SearchBar;
